import React, {useEffect, useState} from "react";
import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import {
    required,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
    TextInput,
    useGetOne,
    useRecordContext,
    FormDataConsumer
} from "react-admin";
import {parse} from "query-string";
import {ColorInput} from 'react-admin-color-input';

export const AttributeValueCreate = props => {
    let {comparableItem: comparableItemUri} = parse(props.location.search);
    let {comparatorConfig: comparatorConfigUri} = parse(props.location.search);
    const encodedComparatorConfigUri = encodeURIComponent(comparatorConfigUri);
    const encodedComparableItemUri = encodeURIComponent(comparableItemUri);
    const redirect = comparatorConfigUri ? `/comparable_items/${encodedComparableItemUri}/attribute_values` : false;

    return (
        <CreateGuesser {...props} redirect={redirect} initialValues={{comparableItem: comparableItemUri}}>
            <ReferenceInput
                source="attribute"
                reference="attributes"
                label="Attribute"
                filterToQuery={searchText => ({name: searchText})}
                filter={{'comparatorConfig': encodedComparatorConfigUri}}
            >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <InputGuesser source={"value"} validate={[required()]}/>
            <ArrayInput source="values">
                <SimpleFormIterator inline>
                    <TextInput source="value" label="Value" helperText={false}/>
                </SimpleFormIterator>
            </ArrayInput>

            <ReferenceInput
                source="comparableItem"
                reference="comparable_items"
                label="Comparable Item"
                filterToQuery={searchText => ({title: searchText})}
                filter={{'comparatorConfig': encodedComparatorConfigUri}}
            >
                <AutocompleteInput optionText="title"/>
            </ReferenceInput>
        </CreateGuesser>
    );
}

const editRedirect = (basePath, id, data) => {
    console.log('basePath, id, data', basePath, id, data);
    return `/comparable_items/${encodeURIComponent(data.comparableItem)}/attribute_values`
};


const AttributeValueEditPartial = () => {
    const record = useRecordContext();

    const [comparableItem, setComparableItem] = useState(null);
    const {
        data: comparableItemData,
        loading: loadingComparableItemData,
        error
    } = useGetOne('comparable_items', record.comparableItem);


    useEffect(() => {
        if (comparableItem) return;
        setComparableItem(comparableItemData)
    }, [comparableItemData])

    return (
        <>
            {comparableItem &&
                <>
                    <ReferenceInput
                        source="comparableItem"
                        reference="comparable_items"
                        label="Comparable Item"
                        filterToQuery={searchText => ({title: searchText})}
                        filter={{'comparatorConfig': comparableItem.comparatorConfig}}>
                        <AutocompleteInput optionText="title"/>
                    </ReferenceInput>

                    <ReferenceInput
                        source="attribute"
                        reference="attributes"
                        label="Attribute"
                        filterToQuery={searchText => ({name: searchText})}
                        filter={{'comparatorConfig': comparableItem.comparatorConfig}}
                        format={(v) => (v && v["@id"]) || v}>
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                </>
            }
        </>
    );
}

export const AttributeValueEdit = props => {
    // const [mutate, { loading }] = useMutation();

    // const load = event => mutate({
    //     type: 'get',
    //     resource: 'comments',
    //     payload: {
    //         id: event.target.dataset.id,
    //         data: { isApproved: true, updatedAt: new Date() }
    //     },
    // });

    // const [comparableItem, setComparableItem] = useState(null);
    // const [comparatorConfig, setComparatorConfig] = useState(null);

    // useEffect(() => {
    //     console.log('comparableItemData', comparableItemData);
    //
    // }, [comparableItem]);
    // const { data, loading, error } = useGetOne('comparable_items', 1);


    return (
        <EditGuesser {...props} redirect={editRedirect} className="attributeValueForm">
            <AttributeValueEditPartial/>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.attribute && formData.attribute.type === 'single_value' &&
                    <InputGuesser source={"value"} validate={[required()]} {...rest}/>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    return (formData.attribute && formData.attribute.type !== 'single_value' &&
                        <ArrayInput source="values" {...rest}>
                            <SimpleFormIterator inline>
                                {formData.attribute.type === 'colors' ?
                                    <ColorInput source="value" label="Color" helperText={false}/> :
                                    <TextInput source="value" label="Value" helperText={false}/>}
                            </SimpleFormIterator>
                        </ArrayInput>)
                }
                }
            </FormDataConsumer>
        </EditGuesser>
    )
};
