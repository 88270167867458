import React from 'react';
import { Link } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddProductButton = ({ classes, record }) =>
{
  console.log('AddProductButton record', record);
  let args = '';
  if (record['@type'] === "ProductRange") {
    args = `productRange=${record['@id']}&brand=${record.brand}&project=${record.project}`;
  } else if (record['@type'] === "Brand") {
    args = `brand=${record['@id']}&project=${record.project}`;
  }
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={`/products/create?${args}`}
      label="Add a product"
      title="Add a product"
    >
      <AddBoxIcon />
    </Button>
  );
}

export default withStyles(styles)(AddProductButton);
