import React from "react";

import {uploadDocument, uploadImage, uploadVideo} from "../mediaUploader";

import {handleQuizConfigResource, handleQuizQuestionCategoryResource, handleQuizQuestionResource} from "./quiz";

import {handleComparableItemResource, handleComparatorConfigResource} from "./comparator";
import {handlePostResource} from "./post";
import {handleBulletTimeConfigResource} from "./bulletTimeConfig";
import {handlePhotoBoothConfigResource} from "./PhotoBoothConfig";
import {handleAccountResource} from "./account";
import {handleMailConfigResource} from "./mailConfig";
import {handleDeviceCircleResource} from "./deviceCircle";
import {handleDeviceResource} from "./device";
import {
  handleConfigurationItemValueResource,
  handleConfiguratorConfigsResource,
  handleConfiguratorItemResource,
  handleDeskConfigurationsResource
} from "./configurator";
import {handleContentWallConfigResource} from "./contentWallConfig";
import {handleBoothConfigResource} from "./boothConfig";
import {handleCheckOutConfigResource} from "./checkOutConfig";
import {handleCheckinConfigResource} from "./checkInConfig";
import {
  handleCategoryResource,
  handleProductColorResource,
  handleProductRangeResource,
  handleProductResource,
  handleProductVariantResource,
  handleProductVersionResource
} from "./product";
import {handleFormChoiceResource} from "./form";
import {handleBrandResource} from "./brand";

import {Redirect, Route} from "react-router-dom";
import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider,
  useIntrospection
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import {handlePlayListItemResource} from "./playListItem";
import {handleAttributeResource} from "./attribute";
import {handleAttributeValueResource} from "./attributeValue";
import {handleShowyAiConfigResource} from "./showyAiConfig";
import {handleAiResourceResource} from "./aiTool";
import {dispatchProjectsModified, getSelectedProject} from "../atom/project";


const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const getHeaders = () => localStorage.getItem("token") ? {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });
const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("token")) {
    introspect();
    return <></>;
  }
  return <Redirect to="/login"/>;
};
const apiDocumentationParser = async (entrypoint) => {
  try {
    const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders});
    return {api};
  } catch (result) {
    if (result.status === 401) {
      // Prevent infinite loop if the token is expired
      localStorage.removeItem("token");

      return {
        api: result.api,
        customRoutes: [
          <Route path="/" component={RedirectToLogin}/>
        ],
      };
    }

    throw result;
  }
};
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);

const myDataProvider = {
  ...dataProvider,
  getList: async (resource, params) => {
    const selectedProject = getSelectedProject();
    const filter = {...params.filter};
    if (selectedProject && selectedProject['@id']) {
      filter.project = selectedProject['@id'];
    }
    return  dataProvider.getList(resource, {
      ...params,
      filter: filter
    });
  },
  create: async (resource, params) => {
    let data = params.data;
    // set project from the selected project from the app bar project selector component:
    const selectedProject = getSelectedProject();
    if (selectedProject && selectedProject['@id']) {
      data.project = selectedProject['@id'];
    }

    switch (resource) {
      case 'attributes':
        data = await handleAttributeResource(params)
        break;
      case 'attribute_values':
        data = await handleAttributeValueResource(params)
        break;
      case 'quiz_configs':
        data = await handleQuizConfigResource(params)
        break;
      case 'quiz_question_categories':
        data = await handleQuizQuestionCategoryResource(params)
        break;
      case 'posts':
        data = await handlePostResource(params)
        break;
      case 'configurator_items':
        data = await handleConfiguratorItemResource(params)
        break;
      case 'form_choices':
        data = await handleFormChoiceResource(params)
        break;
      case 'brands':
        data = await handleBrandResource(params)
        break;
      case 'products':
        data = await handleProductResource(params)
        break;
      case 'categories':
        data = await handleCategoryResource(params)
        break;
      case 'product_ranges':
        data = await handleProductRangeResource(params)
        break;
      case 'product_variants':
        data = await handleProductVariantResource(params)
        break;
      case 'product_versions':
        data = await handleProductVersionResource(params)
        break;
      case 'product_colors':
        data = await handleProductColorResource(params)
        break;
      case 'check_in_configs':
        data = await handleCheckinConfigResource(params)
        break;
      case 'comparator_configs':
        data = await handleComparatorConfigResource(params)
        break;
      case 'comparable_items':
        data = await handleComparableItemResource(params)
        break;
      case 'check_out_configs':
        data = await handleCheckOutConfigResource(params)
        break;
      case 'booth_configs':
        data = await handleBoothConfigResource(params)
        break;
      case 'content_wall_configs':
        data = await handleContentWallConfigResource(params)
        break;
      case 'bullet_time_configs':
        data = await handleBulletTimeConfigResource(params)
        break;
      case 'photo_booth_configs':
        data = await handlePhotoBoothConfigResource(params)
        break;
      case 'accounts':
        data = await handleAccountResource(params)
        break;
      case 'mail_configs':
        data = await handleMailConfigResource(params)
        break;
      case 'configurator_configs':
        data = await handleConfiguratorConfigsResource(params)
        break;
      case 'configuration_item_values':
        data = await handleConfigurationItemValueResource(params)
        break;
      case 'play_list_items':
        data = await handlePlayListItemResource(params)
        break;
      case 'desk_configurations':
        data = await handleDeskConfigurationsResource(params)
        break;
      case 'documents':
        const doc = await uploadDocument(params.data.file);
        return {data: {...doc, id: doc['@id']}};
      case 'images':
        const img = await uploadImage(params.data.file);
        return {data: {...img, id: img['@id']}};
      case 'videos':
        const vid = await uploadVideo(params.data.file);
        return {data: {...vid, id: vid['@id']}};
      case 'showy_ai_configs':
        data = await handleShowyAiConfigResource(params)
        break;
      case 'ai_tools':
        data = await handleAiResourceResource(params)
        break;
      default:
        break;
    }
    const result = dataProvider.create(resource, {
      ...params,
      data: {
        ...data,
      },
    });
    setTimeout(()=>{
      dispatchProjectsModified({type: 'projects_modified', payload: result});
    }, 500)

    return result;
  },
  update: async (resource, params) => {
    let data = params.data;
    switch (resource) {
      case 'attributes':
        data = await handleAttributeResource(params)
        break;
      case 'attribute_values':
        data = await handleAttributeValueResource(params)
        break;
      case 'device_circles':
        data = await handleDeviceCircleResource(params)
        break;
      case 'devices':
        data = await handleDeviceResource(params)
        break;
      case 'quiz_configs':
        data = await handleQuizConfigResource(params)
        break;
      case 'quiz_questions':
        data = await handleQuizQuestionResource(params)
        break;
      case 'quiz_question_categories':
        data = await handleQuizQuestionCategoryResource(params)
        break;
      case 'posts':
        data = await handlePostResource(params)
        break;
      case 'accounts':
        data = await handleAccountResource(params)
        break;
      case 'mail_configs':
        data = await handleMailConfigResource(params)
        break;
      case 'brands':
        data = await handleBrandResource(params)
        break;
      case 'products':
        data = await handleProductResource(params)
        break;
      case 'categories':
        data = await handleCategoryResource(params)
        break;
      case 'product_ranges':
        data = await handleProductRangeResource(params)
        break;
      case 'product_variants':
        data = await handleProductVariantResource(params)
        break;
      case 'product_versions':
        data = await handleProductVersionResource(params)
        break;
      case 'product_colors':
        data = await handleProductColorResource(params)
        break;
      case 'configurator_configs':
        data = await handleConfiguratorConfigsResource(params)
        break;
      case 'desk_configurations':
        data = await handleDeskConfigurationsResource(params)
        break;
      case 'configurator_items':
        data = await handleConfiguratorItemResource(params)
        break;
      case 'form_choices':
        data = await handleFormChoiceResource(params)
        break;
      case 'check_in_configs':
        data = await handleCheckinConfigResource(params)
        break;
      case 'comparator_configs':
        data = await handleComparatorConfigResource(params)
        break;
      case 'comparable_items':
        data = await handleComparableItemResource(params)
        break;
      case 'check_out_configs':
        data = await handleCheckOutConfigResource(params)
        break;
      case 'booth_configs':
        data = await handleBoothConfigResource(params)
        break;
      case 'content_wall_configs':
        data = await handleContentWallConfigResource(params)
        break;
      case 'bullet_time_configs':
        data = await handleBulletTimeConfigResource(params)
        break;
      case 'photo_booth_configs':
        data = await handlePhotoBoothConfigResource(params)
        break;
      case 'configuration_item_values':
        data = await handleConfigurationItemValueResource(params)
        break;
      case 'play_list_items':
        data = await handlePlayListItemResource(params)
        break;
      case 'showy_ai_configs':
        data = await handleShowyAiConfigResource(params)
        break;
      case 'ai_tools':
        data = await handleAiResourceResource(params)
        break;
      default:
        break;
    }
    const result = dataProvider.update(resource, {
      ...params,
      data: {
        ...data,
      },
    });
    setTimeout(()=>{
      dispatchProjectsModified({type: 'projects_modified', payload: result});
    }, 500)

    return result;
  },
};

export {myDataProvider, apiDocumentationParser, parseHydraDocumentation, entrypoint};
