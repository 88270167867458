import React from "react";
import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import {required, SelectInput, TextInput} from "react-admin";
import {parse} from "query-string";

const types = [
    {id: 'string', name: 'String'},
    {id: 'object', name: 'Object'},
    {id: 'array', name: 'Array'},
];

export const AiParameterCreate = props => {
    let {tool: toolUri} = parse(props.location.search);
    const encodedToolUri = encodeURIComponent(toolUri);
    const redirect = toolUri ? `/ai_tools/${encodedToolUri}/parameters` : false;

    return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{tool: toolUri}}>
        <InputGuesser source={"name"} validate={[required()]}/>
        <SelectInput source="type" choices={types} validate={[required()]}/>
        <InputGuesser source={"description"} validate={[required()]}/>
        <InputGuesser source={"optional"} validate={[required()]} defaultValue={true}/>
        <TextInput source="items"
                   parse={v => JSON.parse(v)}
                   format={v => JSON.stringify(v)}
                   fullWidth={true}
                   multiline={true}/>
        <InputGuesser source={"tool"} validate={[required()]} style={{display:'none'}}/>
    </CreateGuesser>
)};

const editRedirect = (basePath, id, data) => {
    return `/ai_tools/${encodeURIComponent(data.tool)}/parameters`
};

export const AiParameterEdit = props => (
    <EditGuesser {...props} redirect={editRedirect}>
        <InputGuesser source={"name"} validate={[required()]}/>
        <SelectInput source="type" choices={types}/>
        <InputGuesser source={"description"} validate={[required()]}/>
        <InputGuesser source={"optional"} validate={[required()]}/>

        <TextInput source="items"
                   parse={v => JSON.parse(v)}
                   format={v => JSON.stringify(v)}
                   fullWidth={true}
                   multiline={true}/>
        <InputGuesser source={"tool"} validate={[required()]} style={{display:'none'}}/>
    </EditGuesser>
);
