import React, {useEffect, useState} from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {useDataProvider} from 'react-admin';
import {selectedProjectAtom, setSelectedProject, projectsModifiedAtom} from "./atom/project";
import {useAtom} from "jotai";

export default function ProjectSelector() {
    const [projects, setProjects] = useState([]);
    const dataProvider = useDataProvider();
    const [project] = useAtom(selectedProjectAtom);
    const [projectsModifiedEvent] = useAtom(projectsModifiedAtom);

    useEffect(() => {
        // Fetch the projects from your API when the component mounts
        dataProvider.getList('projects', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then(({ data }) => {
            setProjects(data);
            if (project && !data.find(a => a['@id'] === project['@id'])) {
                setSelectedProject(projects[0]);
            }
        });
    }, [dataProvider, projectsModifiedEvent]);

    useEffect(() => {
        if (!projects || projects.length === 0) return;
        if (!project) {
            setSelectedProject(projects[0]); // if no project was selected, select the last created project.
        }
        if (project && !projects.find(a => a['@id'] === project['@id'])) {
            setSelectedProject(projects[0]); // reset selected project if it is not owned by connected user.
        }
    }, [project, projects]);

    const handleProjectChange = (event) => {
        setSelectedProject(projects.find(a => a['@id'] === event.target.value))
        // You can set the selected project in global context here
        // or trigger any other action on project change
    };

    return (
        <FormControl
            variant="outlined" sx={{marginLeft: 'auto', marginRight: '10px', minWidth: 200}}>
            <InputLabel
                id="project-selector-label"
                shrink={!!project}
                sx={{ color: 'white' }}
            >Select Project</InputLabel>
            <Select
                labelId="project-selector-label"
                value={project?project['@id']:null}
                onChange={handleProjectChange}
                label="Select Project"
                displayEmpty
                sx={{
                    maxHeight: '30px',
                    color: 'white',  // Make the selected value's text white
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',  // White border
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white',
                    },
                    '.MuiSvgIcon-root': {
                        color: 'white',  // White arrow icon
                    },
                }}
            >
                <MenuItem value="" disabled>
                    <em>Select a project</em>
                </MenuItem>
                {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                        {project.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
