import React, {useContext} from "react";

const StateContext = React.createContext({
  theme: 'light',
  setTheme: (value) => {
    console.log('value: ', value)
  }
});

export const ThemeStateProvider = (props: { children: React.ReactNode }) => {

  const [theme, setTheme] = React.useState('light');

  return (

    <StateContext.Provider value={{
      theme,
      setTheme
    }}>
      {props.children}
    </StateContext.Provider>
  );
};

export const useThemeState = () => useContext(StateContext);
