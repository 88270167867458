import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
  required,
  minLength,
  maxLength,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  Tab,
  TabbedShowLayout,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import AddConfigurationItemButton from "./addConfigurationItemButton";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const ContentWallConfigList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"requireScan"}/>
    <FieldGuesser source={"shareEnabled"}/>
  </RefreshableListBySelectedProject>
);

export const ContentWallConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"requireScan"} validate={[required()]}/>

    <ImageInput source="wallBackground" label="WallBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"wallBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>
    <InputGuesser source={"sessionDuration"} validate={[required()]}/>

    <ImageInput source="wallSideBarBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="scanStateBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="stateBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"wallSideBarBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>
    <InputGuesser source={"wallScanMessage"} multiline fullWidth={true} validate={[required()]}/>
    <InputGuesser source={"wallScanMessageColor"} validate={[minLength(4), maxLength(30)]}/>
    <InputGuesser source={"wallScanMessageBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>

    <ImageInput source="wallScanIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="wallVisitUrlIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"wallVisitUrlBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>
    <InputGuesser source={"wallVisitUrlMessage"} multiline fullWidth={true} validate={[required()]}/>
    <InputGuesser source={"wallVisitUrlColor"} validate={[minLength(4), maxLength(30)]}/>

    <ImageInput source="wallRetrieveContentIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"wallRetrieveContentMessage"} multiline fullWidth={true} validate={[required()]}/>

    <ImageInput source="mobileBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"mobileBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>
    <InputGuesser source={"mobileColor"} validate={[minLength(4), maxLength(30)]}/>

    <ImageInput source="mobileLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="mobileHeaderBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="mobileFooterBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"receiveViaEmailEnabled"}/>
    <InputGuesser source={"mobileRetrieveSectionTitle"} multiline fullWidth={true}/>
    <InputGuesser source={"mobileShareSectionTitle"} multiline fullWidth={true}/>
    <InputGuesser source={"mobileNavigationMessage"} multiline fullWidth={true}/>
    <InputGuesser source={"mobileRetrieveContentMessage"} multiline fullWidth={true} validate={[required()]}/>
    <InputGuesser source={"mobileRetrieveBulletTimeMessage"} multiline fullWidth={true} validate={[required()]}/>
    <InputGuesser source={"wallRetrieveContentMessageColor"} validate={[minLength(4), maxLength(30)]}/>
    <InputGuesser source={"wallRetrieveContentMessageBackgroundColor"}
                  validate={[minLength(4), maxLength(30)]}/>
    <InputGuesser source={"mobileRetrieveShakeItGetItColor"} validate={[minLength(4), maxLength(30)]}/>

    <ImageInput source="mobileRetrieveIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"testDriveRequestLabel"}/>

    <InputGuesser source={"expiredSessionMessage"}/>
    <InputGuesser source={"testDriveRequestSuccessMessage"}/>
    <InputGuesser source={"testDriveRequestErrorMessage"}/>
    <InputGuesser source={"shakePermissionMessage"}/>
    <InputGuesser source={"shakePermissionButtonLabel"}/>

    <InputGuesser source={"shareEnabled"}/>
    <InputGuesser source={"socialMediaShareActionTitle"} fullWidth={true}/>
    <InputGuesser source={"testDriveRequestEnabled"}/>
    <InputGuesser source={"allowAnonymousContentRetrieve"}/>

    <ReferenceArrayInput
      source="brands"
      reference="brands"
      label="Brands"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceArrayInput
      source="products"
      reference="products"
      label="Products"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceArrayInput
      source="productVerions"
      reference="product_versions"
      label="Product Verions"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <InputGuesser source={"boothInitCount"} />
    <InputGuesser source={"bulletTimeInitCount"} />
    <InputGuesser source={"cssStyle"} multiline fullWidth={true}/>
    <InputGuesser source={"mobileCssStyle"} multiline fullWidth={true}/>

    <ReferenceArrayInput
      source="applications"
      reference="applications"
      label="Applications"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      filter={{ 'application.slug': 'content-wall'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>
  </CreateGuesser>
);
export const ContentWallConfigEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} validate={[required()]}/>
        <InputGuesser source={"requireScan"} validate={[required()]}/>

        <ImageInput source="wallBackground" label="WallBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"wallBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>
        <InputGuesser source={"sessionDuration"} validate={[required()]}/>

        <ImageInput source="wallSideBarBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="scanStateBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="stateBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"wallSideBarBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>
        <InputGuesser source={"wallScanMessage"} multiline fullWidth={true} validate={[required()]}/>
        <InputGuesser source={"wallScanMessageColor"} validate={[minLength(4), maxLength(30)]}/>
        <InputGuesser source={"wallScanMessageBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>

        <ImageInput source="wallScanIcon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="wallVisitUrlIcon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"wallVisitUrlBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>
        <InputGuesser source={"wallVisitUrlMessage"} multiline fullWidth={true} validate={[required()]}/>
        <InputGuesser source={"wallVisitUrlColor"} validate={[minLength(4), maxLength(30)]}/>

        <ImageInput source="wallRetrieveContentIcon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"wallRetrieveContentMessage"} multiline fullWidth={true} validate={[required()]}/>

        <ImageInput source="mobileBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"mobileBackgroundColor"} validate={[minLength(4), maxLength(30)]}/>
        <InputGuesser source={"mobileColor"} validate={[minLength(4), maxLength(30)]}/>

        <ImageInput source="mobileLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="mobileHeaderBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="mobileFooterBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"receiveViaEmailEnabled"}/>
        <InputGuesser source={"mobileRetrieveSectionTitle"} multiline fullWidth={true}/>
        <InputGuesser source={"mobileShareSectionTitle"} multiline fullWidth={true}/>

        <InputGuesser source={"mobileNavigationMessage"} multiline fullWidth={true}/>
        <InputGuesser source={"mobileRetrieveContentMessage"} multiline fullWidth={true} validate={[required()]}/>
        <InputGuesser source={"mobileRetrieveBulletTimeMessage"} multiline fullWidth={true} validate={[required()]}/>
        <InputGuesser source={"wallRetrieveContentMessageColor"} validate={[minLength(4), maxLength(30)]}/>
        <InputGuesser source={"wallRetrieveContentMessageBackgroundColor"}
                      validate={[minLength(4), maxLength(30)]}/>
        <InputGuesser source={"mobileRetrieveShakeItGetItColor"} validate={[minLength(4), maxLength(30)]}/>

        <ImageInput source="mobileRetrieveIcon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"testDriveRequestLabel"}/>

        <InputGuesser source={"expiredSessionMessage"}/>
        <InputGuesser source={"testDriveRequestSuccessMessage"}/>
        <InputGuesser source={"testDriveRequestErrorMessage"}/>
        <InputGuesser source={"shakePermissionMessage"}/>
        <InputGuesser source={"shakePermissionButtonLabel"}/>

        <InputGuesser source={"shareEnabled"}/>
        <InputGuesser source={"socialMediaShareActionTitle"} fullWidth={true}/>
        <InputGuesser source={"testDriveRequestEnabled"}/>
        <InputGuesser source={"allowAnonymousContentRetrieve"}/>

        <ReferenceArrayInput
          source="brands"
          reference="brands"
          label="Brands"
          filterToQuery={searchText => ({name: searchText})}
          format={v => v && v.map(x => x['@id'])}>
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceArrayInput
          source="products"
          reference="products"
          label="Products"
          filterToQuery={searchText => ({name: searchText})}
          format={v => v && v.map(x => x['@id'])}>
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceArrayInput
          source="productVerions"
          reference="product_versions"
          label="Product Verions"
          filterToQuery={searchText => ({name: searchText})}
          format={v => v && v.map(x => x['@id'])}>
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <InputGuesser source={"boothInitCount"} />
        <InputGuesser source={"bulletTimeInitCount"} />
        <InputGuesser source={"cssStyle"} multiline fullWidth={true}/>
        <InputGuesser source={"mobileCssStyle"} multiline fullWidth={true}/>

        <ReferenceArrayInput
          source="applications"
          reference="applications"
          label="Applications"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={searchText => ({name: searchText})}
          filter={{ 'application.slug': 'content-wall'}}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>
      </Tab>
      <Tab label="Configurations" path="configurations">
        <ReferenceManyField
          addLabel={false}
          reference="configuration_item_values"
          target="contentWallConfig">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="type"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddConfigurationItemButton context={'contentWallConfig'}/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
