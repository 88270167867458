import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {ReferenceInput, AutocompleteInput, TabbedShowLayout, Tab, TextField, ShowButton, EditButton, ReferenceManyField, Datagrid} from "react-admin";
import {parse} from "query-string";
import AddFormItemButton from "./addFormItemButton";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const FormStepList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"title"}/>
    <FieldGuesser source={"subTitle"}/>
    <FieldGuesser source={"order"}/>
    <FieldGuesser source={"form"}/>
  </RefreshableListBySelectedProject>
);

export const FormStepCreate = props => {
  const {form: formUri} = parse(props.location.search);

  const encodedFormUri = encodeURIComponent(formUri);

  const redirect = formUri ? `/forms/${encodedFormUri}/steps` : false;

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{form: formUri}}>
      <InputGuesser source={"title"}/>
      <InputGuesser source={"subTitle"}/>
      <InputGuesser source={"order"}/>
      <ReferenceInput
        allowEmpty={true}
        source="form"
        reference="forms"
        label="Form"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  )
};

const redirect = (basePath, id, data) => {
  const formUri = encodeURIComponent(data.form);
  return data.form ? `/forms/${formUri}/steps` : `/form_steps`;
};

export const FormStepEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"title"}/>
        <InputGuesser source={"subTitle"}/>
        <InputGuesser source={"order"}/>
        <ReferenceInput
          source="form"
          reference="forms"
          label="Form"
          filterToQuery={searchText => ({name: searchText})}>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Form Items" path="items">
        <ReferenceManyField
          addLabel={false}
          reference="form_items"
          target="formStep"
        >
          <Datagrid>
            <TextField source="label"/>
            <TextField source="name"/>
            <TextField source="type"/>
            <FieldGuesser source="required"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddFormItemButton context={'form_step'}/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
