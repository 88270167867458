import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const VideoField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  let contentUrl = record[source];
  if (source.includes('.')) {
    const sourceParts = source.split('.');
    contentUrl = record[sourceParts[0]][sourceParts[1]];
  }
  return <video controls src={contentUrl} width={300}></video>;
}

VideoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  addLabel: true
};

export default VideoField;
