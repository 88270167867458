import {useAtom} from "jotai";
import {selectedProjectAtom} from "./atom/project";
import React, {useEffect} from "react";
import {ListGuesser} from "@api-platform/admin";
import { useRefresh } from 'react-admin';

export const RefreshableListBySelectedProject = (props) => {
    const refresh = useRefresh();
    const [project] = useAtom(selectedProjectAtom);

    useEffect(() => {
        refresh();
    }, [project]);

    return (
        <ListGuesser {...props}>
            {props.children}
        </ListGuesser>
    )
};
