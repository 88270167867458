import React from 'react';
import { Link } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddQuestionButton = ({ classes, record, context }) => {
  let to = `/quiz_questions/create?context=${context}&${context}=${record['@id']}`;
  if (record['@type'] === "QuizQuestionCategory") {
    to = `/quiz_questions/create?context=${context}&${context}=${record['@id']}&quiz=${record['quiz']}`;
  }
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={to}
      label="Add a question"
      title="Add a question"
    >
      <AddBoxIcon />
    </Button>
  );
}

export default withStyles(styles)(AddQuestionButton);
