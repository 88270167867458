import React from 'react';
import { Link } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddHeroProductButton = ({ classes, record }) =>
{
  console.log('AddProductButton record', record);

  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={`/desk_hero_products/create?deskConfiguration=${record['@id']}&project=${record.project}`}
      label="Add a hero product"
      title="Add a hero product"
    >
      <AddBoxIcon />
    </Button>
  );
}

export default withStyles(styles)(AddHeroProductButton);
