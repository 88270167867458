import React, { useEffect, useState } from 'react';
import { SelectInput, useDataProvider } from 'react-admin';

const DIDVoiceSelectInput = (props) => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);

    useEffect(() => {
        dataProvider.getList('d_id_voices', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: {provider:'microsoft'},// todo: make this dynamic from provider field
        })
            .then(({ data }) => {
                setChoices(data.map(item => ({ id: item.voiceId, name: item.voiceId })));
            })
            .catch(error => {
                console.error("Error fetching data", error);
            });
    }, [dataProvider]);

    return (
        <SelectInput
            {...props}
            choices={choices}
            optionText="name"
            optionValue="voice_id"
        />
    );
};

export default DIDVoiceSelectInput;
