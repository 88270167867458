import { uploadDocument, uploadImage } from "../mediaUploader";

export async function handleCheckinConfigResource(params) {
  let data = params.data;
  if (
    params.data.checkInIcon &&
    params.data.checkInIcon.rawFile instanceof File
  ) {
    data.checkInIcon = (await uploadImage(params.data.checkInIcon))["@id"];
  }
  if (
    params.data.mainBackground &&
    params.data.mainBackground.rawFile instanceof File
  ) {
    data.mainBackground = (await uploadImage(params.data.mainBackground))[
      "@id"
    ];
  }
  if (
    params.data.headerBackground &&
    params.data.headerBackground.rawFile instanceof File
  ) {
    data.headerBackground = (await uploadImage(params.data.headerBackground))[
      "@id"
    ];
  }
  if (
    params.data.footerBackground &&
    params.data.footerBackground.rawFile instanceof File
  ) {
    data.footerBackground = (await uploadImage(params.data.footerBackground))[
      "@id"
    ];
  }
  if (params.data.scanIcon && params.data.scanIcon.rawFile instanceof File) {
    data.scanIcon = (await uploadImage(params.data.scanIcon))["@id"];
  }
  if (
    params.data.successIcon &&
    params.data.successIcon.rawFile instanceof File
  ) {
    data.successIcon = (await uploadImage(params.data.successIcon))["@id"];
  }
  if (
    params.data.submitButtonBackground &&
    params.data.submitButtonBackground.rawFile instanceof File
  ) {
    data.submitButtonBackground = (
      await uploadImage(params.data.submitButtonBackground)
    )["@id"];
  }
  if (params.data.inputBg && params.data.inputBg.rawFile instanceof File) {
    data.inputBg = (await uploadImage(params.data.inputBg))["@id"];
  }
  if (
    params.data.closeButtonIcon &&
    params.data.closeButtonIcon.rawFile instanceof File
  ) {
    data.closeButtonIcon = (await uploadImage(params.data.closeButtonIcon))[
      "@id"
    ];
  }
  if (
    params.data.appointmentButtonIcon &&
    params.data.appointmentButtonIcon.rawFile instanceof File
  ) {
    data.appointmentButtonIcon = (await uploadImage(params.data.appointmentButtonIcon))[
      "@id"
    ];
  }
  if (
    params.data.formBackground &&
    params.data.formBackground.rawFile instanceof File
  ) {
    data.formBackground = (await uploadImage(params.data.formBackground))[
      "@id"
    ];
  }
  if (params.data.font && params.data.font.rawFile instanceof File) {
    data.font = (await uploadDocument(params.data.font))["@id"];
  }
  return data;
}
