import {uploadImage, uploadVideo} from "../mediaUploader";

export async function handleQuizConfigResource(params) {
  let data = params.data;
  if (typeof params.data.introButtonImage !== 'undefined' && params.data.introButtonImage !== null && params.data.introButtonImage.rawFile instanceof File) {
    data.introButtonImage = (await uploadImage(params.data.introButtonImage, true))['@id'];
  }
  if (typeof params.data.completeIcon !== 'undefined' && params.data.completeIcon !== null && params.data.completeIcon.rawFile instanceof File) {
    data.completeIcon = (await uploadImage(params.data.completeIcon, true))['@id'];
  }
  if (typeof params.data.scanBackground !== 'undefined' && params.data.scanBackground !== null && params.data.scanBackground.rawFile instanceof File) {
    data.scanBackground = (await uploadImage(params.data.scanBackground, true))['@id'];
  }
  if (typeof params.data.scanVideo !== 'undefined' && params.data.scanVideo !== null && params.data.scanVideo.rawFile instanceof File) {
    data.scanVideo = (await uploadVideo(params.data.scanVideo, true))['@id'];
  }
  if (typeof params.data.wrongAnswerIcon !== 'undefined' && params.data.wrongAnswerIcon !== null && params.data.wrongAnswerIcon.rawFile instanceof File) {
    data.wrongAnswerIcon = (await uploadImage(params.data.wrongAnswerIcon, true))['@id'];
  }
  if (typeof params.data.correctAnswerIcon !== 'undefined' && params.data.correctAnswerIcon !== null && params.data.correctAnswerIcon.rawFile instanceof File) {
    data.correctAnswerIcon = (await uploadImage(params.data.correctAnswerIcon, true))['@id'];
  }
  if (typeof params.data.choiceIcon !== 'undefined' && params.data.choiceIcon !== null && params.data.choiceIcon.rawFile instanceof File) {
    data.choiceIcon = (await uploadImage(params.data.choiceIcon, true))['@id'];
  }
  if (typeof params.data.selectedChoiceIcon !== 'undefined' && params.data.selectedChoiceIcon !== null && params.data.selectedChoiceIcon.rawFile instanceof File) {
    data.selectedChoiceIcon = (await uploadImage(params.data.selectedChoiceIcon, true))['@id'];
  }
  if (typeof params.data.incorrectChoiceIcon !== 'undefined' && params.data.incorrectChoiceIcon !== null && params.data.incorrectChoiceIcon.rawFile instanceof File) {
    data.incorrectChoiceIcon = (await uploadImage(params.data.incorrectChoiceIcon, true))['@id'];
  }
  if (typeof params.data.correctChoiceIcon !== 'undefined' && params.data.correctChoiceIcon !== null && params.data.correctChoiceIcon.rawFile instanceof File) {
    data.correctChoiceIcon = (await uploadImage(params.data.correctChoiceIcon, true))['@id'];
  }
  if (typeof params.data.headerBackgroundImage !== 'undefined' && params.data.headerBackgroundImage !== null && params.data.headerBackgroundImage.rawFile instanceof File) {
    data.headerBackgroundImage = (await uploadImage(params.data.headerBackgroundImage, true))['@id'];
  }
  if (typeof params.data.introBackgroundImage !== 'undefined' && params.data.introBackgroundImage !== null && params.data.introBackgroundImage.rawFile instanceof File) {
    data.introBackgroundImage = (await uploadImage(params.data.introBackgroundImage, true))['@id'];
  }
  return data;
}

export async function handleQuizQuestionCategoryResource(params) {
  let data = params.data;
  if (typeof params.data.image !== 'undefined' && params.data.image !== null && params.data.image.rawFile instanceof File) {
    data.image = (await uploadImage(params.data.image, true))['@id'];
  }
  if (typeof params.data.video !== 'undefined' && params.data.video !== null && params.data.video.rawFile instanceof File) {
    data.video = (await uploadVideo(params.data.video))['@id'];
  }
  return data;
}

export async function handleQuizQuestionResource(params) {
  const data = params.data;
  if (params.data.category) {
    data.category = params.data.category['@id'];
  }
  return data;
}
