import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import CustomImageField from "../components/fields/CustomImageField";
import {
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
  required,
  FileInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
} from "react-admin";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import RichTextInput from "ra-input-rich-text";
import {parse} from "query-string";
import AddProductColorButton from "./addProductColorButton";

export const ProductVersionCreate = props => {
  const {product: productUri} = parse(props.location.search);
  const encodedProductUri = encodeURIComponent(productUri);
  const redirect = productUri ? `/products/${encodedProductUri}/versions` : false;
  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{product: productUri}}>
      <InputGuesser source="name"/>
      <InputGuesser source="price"/>
      <RichTextInput source={"description"} fullWidth={true} multiline validate={[required()]}/>
      <ImageInput source="photo" label="Photo" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>

      <FileInput source="technicalDocument" label="Technical Document" placeholder={<p>Drop your file here</p>}>
        <CustomDocumentField source="name"/>
      </FileInput>

      <ReferenceInput
        allowEmpty={true}
        source="product"
        reference="products"
        label="Product"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const productUri = encodeURIComponent(data.product);
  return `/products/${productUri}/versions`;
};

export const ProductVersionEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>
        <InputGuesser source="price"/>
        <RichTextInput source={"description"} fullWidth={true} multiline validate={[required()]}/>
        <ImageInput source="photo" label="Photo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <FileInput source="technicalDocument" label="Technical Document" placeholder={<p>Drop your file here</p>}>
          <CustomDocumentField source="name"/>
        </FileInput>

        <ReferenceInput
          allowEmpty={true}
          source="product"
          reference="products"
          label="Product"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>


      </Tab>
      <Tab label="Colors" path="colors">
        <ReferenceManyField
          addLabel={false}
          reference="product_colors"
          target="productVersion">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="colorName"/>
            <TextField source="colorValue"/>
            <TextField source="position"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddProductColorButton context={'product_version'}/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
