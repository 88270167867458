import {uploadDocument, uploadImage, uploadVideo} from "../mediaUploader";

export async function handleConfiguratorItemResource(params) {
  let data = params.data;
  if (Array.isArray(params.data.imageColorItems) && params.data.imageColorItems.length > 0) {
    for (const [index, item] of params.data.imageColorItems.entries()) {
      if (item.image && item.image.rawFile instanceof File) {
        data.imageColorItems[index].image = (await uploadImage(item.image))['@id'];
      }
    }
  }

  return data;
}

export async function handleConfiguratorConfigsResource(params) {
  let data = params.data;

  if (params.data.mainBackgroundImage && params.data.mainBackgroundImage.rawFile instanceof File) {
    data.mainBackgroundImage = (await uploadImage(params.data.mainBackgroundImage))['@id'];
  }
  if (params.data.brandLogo && params.data.brandLogo.rawFile instanceof File) {
    data.brandLogo = (await uploadImage(params.data.brandLogo))['@id'];
  }
  if (params.data.scanIcon && params.data.scanIcon.rawFile instanceof File) {
    data.scanIcon = (await uploadImage(params.data.scanIcon))['@id'];
  }
  delete data.configuratorItems;

  return data;
}

export async function handleDeskConfigurationsResource(params) {
  let data = params.data;
  delete data.heroProducts;

  return data;
}

export async function handleConfigurationItemValueResource(params) {
  let data = params.data;

  if (params.data.image) {
    if (params.data.image.rawFile instanceof File) {
      data.image = (await uploadImage(params.data.image))['@id'];
    } else if (typeof data.image === 'object') {
      data.image = params.data.image['@id']
    }
  }

  if (params.data.video) {
    if (params.data.video.rawFile instanceof File) {
      data.video = (await uploadVideo(params.data.video))['@id'];
    } else if (typeof data.video === 'object') {
      data.video = params.data.video['@id']
    }
  }

  if (params.data.document) {
    if (params.data.document.rawFile instanceof File) {
      data.document = (await uploadDocument(params.data.document))['@id'];
    } else if (typeof data.document === 'object') {
      data.document = params.data.document['@id']
    }
  }

  return data;
}
