import decodeJwt from 'jwt-decode';

const authProvider = {
  login: ({username, password}) => {
    const request = new Request(`${process.env.REACT_APP_API_ENTRYPOINT}/authenticate_check`, {
      method: 'POST',
      body: JSON.stringify({username, password}),
      headers: new Headers({'Content-Type': 'application/json'}),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({token}) => {
        const decodedToken = decodeJwt(token);
        localStorage.setItem('token', token);
        localStorage.setItem('permissions', decodedToken.roles);
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  checkAuth: params => {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  },
  checkError: (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('permissions');
      return Promise.reject(error);
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  }
};

export default authProvider;
