import * as React from 'react';
import {AppBar, usePermissions} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import {useThemeState} from "./theme";
import {Switch} from '@material-ui/core';
import ProjectSelector from "./ProjectSelector";
import isGranted from "./security";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const MyAppBar = props => {
    const classes = useStyles();
    const {setTheme, theme} = useThemeState();
    const {loaded, permissions} = usePermissions();

    return loaded ? (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
                text={"tetsttstst"}
            />
            {!isGranted("ROLE_SUPER_ADMIN", permissions) &&
                <ProjectSelector/>
            }
            <span className={classes.spacer}/>
            <Switch
                source="Theme"
                icon={<Brightness7Icon/>}
                checkedIcon={<Brightness4Icon/>}
                onChange={() => {
                    setTheme(theme === 'light' ? 'dark' : 'light')
                }}
            />
        </AppBar>
    ) : null;
};

export default MyAppBar;
