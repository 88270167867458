import React from 'react';
import {Link} from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {withStyles} from '@material-ui/core/styles';
import {Button} from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddProductColorButton = ({classes, record, context}) => {
  let to = `/product_colors/create?context=${context}&${context}=${record['@id']}`;
  if (record['@type'] === "ProductVersion") {
    to = `/product_colors/create?context=${context}&${context}=${record['@id']}&product=${record['product']}`;
  }
  return (
    <Button
      className={classes.button}
      variant="text"
      component={Link}
      to={to}
      label="Add a color"
      title="Add a color"
    >
      <AddBoxIcon/>
    </Button>
  )
};

export default withStyles(styles)(AddProductColorButton);
