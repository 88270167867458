import {uploadImage} from "../mediaUploader";


export async function handleBulletTimeConfigResource(params) {
  const data = params.data;
  if (params.data.brandingLogo.rawFile instanceof File) {
    data.brandingLogo = (await uploadImage(params.data.brandingLogo))['@id'];
  }
  return data;
}
