import React from 'react';
import { Link } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddConfigurationItemButton = ({ classes, record, context }) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/configuration_item_values/create?context=${context}&${context}=${record['@id']}`}
    label="Add a configuration item"
    title="Add a configuration item"
  >
    <AddBoxIcon />
  </Button>
);

export default withStyles(styles)(AddConfigurationItemButton);
