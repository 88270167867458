import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  SelectInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
  FileInput,
  required,
  minLength
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const CheckOutConfigList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"scanType"}/>
  </RefreshableListBySelectedProject>
);

export const CheckOutConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"mainBackgroundColor"} validate={[required(), minLength(4)]}/>
    <InputGuesser source={"titleColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"subTitleColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"mainColor"} validate={[required(), minLength(4)]}/>
    <ImageInput source="mainBackground" label="MainBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <ImageInput source="headerBackground" label="HeaderBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <ImageInput source="footerBackground" label="FooterBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <ImageInput source="scanIcon" label="ScanIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <InputGuesser source={"scanMessage"}/>
    <ImageInput source="successIcon" label="SuccessIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <InputGuesser source={"successTitle"} validate={[required()]}/>
    <InputGuesser source={"successSubTitle"} validate={[required()]}/>
    <InputGuesser source={"buttonColor"} validate={[required(), minLength(4)]}/>
    <InputGuesser source={"buttonBgColor"} validate={[required(), minLength(4)]}/>

    <InputGuesser source={"inputColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"inputBackgroundColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"inputFocusBackgroundColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"inputBorderRadius"}/>

    <InputGuesser source={"closeButtonLabel"}/>
    <InputGuesser source={"closeButtonColor"} validate={[minLength(4)]}/>
    <ImageInput source="closeButtonIcon" label="CloseButtonIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"cssStyle"}/>

    <FileInput source="font" label="Font" placeholder={<p>Drop your file here</p>}>
      <CustomDocumentField source="name"/>
    </FileInput>

    <SelectInput label="ScanType" source="scanType" choices={[
      {id: 'qrcode', name: 'QRCode'},
      {id: 'nfc', name: 'NFC'},
    ]} validate={[required()]}/>
    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>
  </CreateGuesser>
);
export const CheckOutConfigEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"mainBackgroundColor"} validate={[required(), minLength(4)]}/>
    <InputGuesser source={"titleColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"subTitleColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"mainColor"} validate={[required(), minLength(4)]}/>
    <ImageInput source="mainBackground" label="MainBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <ImageInput source="headerBackground" label="HeaderBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <ImageInput source="footerBackground" label="FooterBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <ImageInput source="scanIcon" label="ScanIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <InputGuesser source={"scanMessage"}/>
    <ImageInput source="successIcon" label="SuccessIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <InputGuesser source={"successTitle"} validate={[required()]}/>
    <InputGuesser source={"successSubTitle"} validate={[required()]}/>
    <InputGuesser source={"buttonColor"} validate={[required(), minLength(4)]}/>
    <InputGuesser source={"buttonBgColor"} validate={[required(), minLength(4)]}/>

    <InputGuesser source={"inputColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"inputBackgroundColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"inputFocusBackgroundColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"inputBorderRadius"}/>

    <InputGuesser source={"closeButtonLabel"}/>
    <InputGuesser source={"closeButtonColor"} validate={[minLength(4)]}/>
    <ImageInput source="closeButtonIcon" label="CloseButtonIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"cssStyle"}/>

    <FileInput source="font" label="Font" placeholder={<p>Drop your file here</p>}>
      <CustomDocumentField source="name"/>
    </FileInput>

    <SelectInput label="ScanType" source="scanType" choices={[
      {id: 'qrcode', name: 'QRCode'},
      {id: 'nfc', name: 'NFC'},
    ]} validate={[required()]}/>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>
  </EditGuesser>
);
