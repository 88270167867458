import {uploadImage} from "../mediaUploader";

export async function handleContentWallConfigResource(params) {
  let data = params.data;
  if (params.data.wallBackground && params.data.wallBackground.rawFile instanceof File) {
    data.wallBackground = (await uploadImage(params.data.wallBackground))['@id'];
  }
  if (params.data.wallSideBarBackground && params.data.wallSideBarBackground.rawFile instanceof File) {
    data.wallSideBarBackground = (await uploadImage(params.data.wallSideBarBackground))['@id'];
  }
  if (params.data.scanStateBackground && params.data.scanStateBackground.rawFile instanceof File) {
    data.scanStateBackground = (await uploadImage(params.data.scanStateBackground))['@id'];
  }
  if (params.data.wallScanIcon && params.data.wallScanIcon.rawFile instanceof File) {
    data.wallScanIcon = (await uploadImage(params.data.wallScanIcon))['@id'];
  }
  if (params.data.wallVisitUrlIcon && params.data.wallVisitUrlIcon.rawFile instanceof File) {
    data.wallVisitUrlIcon = (await uploadImage(params.data.wallVisitUrlIcon))['@id'];
  }
  if (params.data.wallRetrieveContentIcon && params.data.wallRetrieveContentIcon.rawFile instanceof File) {
    data.wallRetrieveContentIcon = (await uploadImage(params.data.wallRetrieveContentIcon))['@id'];
  }
  if (params.data.mobileBackground && params.data.mobileBackground.rawFile instanceof File) {
    data.mobileBackground = (await uploadImage(params.data.mobileBackground))['@id'];
  }
  if (params.data.mobileLogo && params.data.mobileLogo.rawFile instanceof File) {
    data.mobileLogo = (await uploadImage(params.data.mobileLogo))['@id'];
  }
  if (params.data.mobileHeaderBackground && params.data.mobileHeaderBackground.rawFile instanceof File) {
    data.mobileHeaderBackground = (await uploadImage(params.data.mobileHeaderBackground))['@id'];
  }
  if (params.data.mobileFooterBackground && params.data.mobileFooterBackground.rawFile instanceof File) {
    data.mobileFooterBackground = (await uploadImage(params.data.mobileFooterBackground))['@id'];
  }
  if (params.data.mobileRetrieveIcon && params.data.mobileRetrieveIcon.rawFile instanceof File) {
    data.mobileRetrieveIcon = (await uploadImage(params.data.mobileRetrieveIcon))['@id'];
  }
  if (params.data.stateBackground && params.data.stateBackground.rawFile instanceof File) {
    data.stateBackground = (await uploadImage(params.data.stateBackground))['@id'];
  }
  return data;
}
