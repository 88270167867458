import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {SelectInput, ReferenceInput, AutocompleteInput, usePermissions, TabbedShowLayout, Tab, required} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import isGranted from "../security";
import {Redirect} from 'react-router';

export const ProjectList = props => {
  const {loaded, permissions} = usePermissions();
  if (!loaded) {
    return null;
  }
  if (!isGranted('ROLE_ADMIN', permissions)) {
    return (<Redirect to="/people"/>);
  }
  return (
    <ListGuesser {...props}>
      <FieldGuesser source={"name"}/>
      <FieldGuesser source={"type"}/>
      <FieldGuesser source={"zohoIntegrationEnabled"}/>
      <FieldGuesser source={"isSyncWithGoogleSheetsEnabled"}/>
    </ListGuesser>
  );
}

export const ProjectCreate = ({permissions, ...props}) => (
  <CreateGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name" validate={required()}/>
        <SelectInput source="type" choices={[
          {id: 'showy_ai', name: 'Showy AI'},
          {id: 'experiences', name: 'Experiences'},
        ]} validate={required()}/>
        <RichTextInput source="description" theme={'snow'}
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>

        <ReferenceInput
          allowEmpty={true}
          source="syncForm"
          reference="forms"
          label="Sync Form"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <InputGuesser source="emptyPersonUIDOnCheckOut"/>

        <InputGuesser source="currentVehicleZohoFieldName"/>
        <InputGuesser source="optInZohoFieldName"/>

        {isGranted('ROLE_SUPER_ADMIN', permissions) &&
          <ReferenceInput
            allowEmpty={true}
            source="account"
            reference="accounts"
            label="Account"
            validate={required()}
            filterToQuery={searchText => ({name: searchText})}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </Tab>
      <Tab label="Google Sheets">
        <InputGuesser source="isSyncWithGoogleSheetsEnabled" />
        <InputGuesser source="googleSheetsCredentials" fullWidth multiline />
        <InputGuesser source="googleSheetsSpreadsheetId" />
        <InputGuesser source="googleSheetsSheetName" />
        <InputGuesser source="isGoogleSheetsSyncInitialized" />
      </Tab>
      <Tab label="Zoho">
        <InputGuesser source="zohoIntegrationEnabled"/>
        <InputGuesser source="zohoClientId"/>
        <InputGuesser source="zohoClientSecret"/>
        <InputGuesser source="zohoGrantToken"/>
        <InputGuesser source="zohoRedirectUri"/>
        <InputGuesser source="zohoUserEmail"/>
      </Tab>
    </TabbedShowLayout>
  </CreateGuesser>
);

export const ProjectEdit = ({permissions, ...props}) => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name" validate={required()}/>
        <RichTextInput source="description" theme={'snow'}
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <SelectInput source="type" choices={[
          {id: 'showy_ai', name: 'Showy AI'},
          {id: 'experiences', name: 'Experiences'},
        ]} validate={required()}/>
        <ReferenceInput
          allowEmpty={true}
          source="syncForm"
          reference="forms"
          label="Sync Form"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <InputGuesser source="emptyPersonUIDOnCheckOut"/>

        <InputGuesser source="currentVehicleZohoFieldName"/>
        <InputGuesser source="optInZohoFieldName"/>

        {isGranted('ROLE_SUPER_ADMIN', permissions) &&
          <ReferenceInput
            allowEmpty={true}
            source="account"
            reference="accounts"
            label="Account"
            validate={required()}
            filterToQuery={searchText => ({name: searchText})}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </Tab>
      <Tab label="Google Sheets">
        <InputGuesser source="isSyncWithGoogleSheetsEnabled" />
        <InputGuesser source="googleSheetsCredentials" />
        <InputGuesser source="googleSheetsSpreadsheetId" />
        <InputGuesser source="googleSheetsSheetName" />
        <InputGuesser source="isGoogleSheetsSyncInitialized" />
      </Tab>
      <Tab label="Zoho">
        <InputGuesser source="zohoIntegrationEnabled"/>
        <InputGuesser source="zohoClientId"/>
        <InputGuesser source="zohoClientSecret"/>
        <InputGuesser source="zohoGrantToken"/>
        <InputGuesser source="zohoRedirectUri"/>
        <InputGuesser source="zohoUserEmail"/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
