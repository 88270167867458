import React from 'react';
import PropTypes from 'prop-types';


const fetchHeaders = () => {
  return {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    'content-type': 'application/ld+json'
  }
};

class CustomImageField extends React.Component {
  state = {
    imgUrl: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.record !== this.props.record || prevProps.source !== this.props.source) {
      this.handleImage();
    }
  }

  componentDidMount() {
    this.handleImage();
  }

  handleImage() {
    const {record, source} = this.props;
    if (record.rawFile instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(record.rawFile);
      reader.onload = () => this.setState({imgUrl: reader.result});
      reader.onerror = err => console.log(err);
    } else if (typeof record === 'string' || typeof record['@id'] === 'string') {
      const imgIri = typeof record === 'string' ? record : record['@id'];

      fetch(process.env.REACT_APP_API_ENTRYPOINT + imgIri, {headers: new Headers(fetchHeaders())})
        .then((response) => {
          response.json().then(data => {
            // TODO: use another property like src to set the property
            if (typeof data[source] === 'undefined') {
              this.setState({imgUrl: data.contentUrl});
            } else {
              this.setState({imgUrl: data[source]});
            }
          })
        });
    } else {
      if (record[source] !== null) {
        this.setState({imgUrl: record[source]});
      }
    }
  }

  render() {
    const {imgUrl} = this.state;
    return (
      <div>
        {imgUrl !== null && (
          <img src={imgUrl} style={{width: '150px', maxWidth: '150px'}} alt=""/>
        )}
      </div>
    );
  }
}

CustomImageField.propTypes = {
  label: PropTypes.string,
  //record: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default CustomImageField;
