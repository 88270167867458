import {uploadImage} from "../mediaUploader";

export async function handleBoothConfigResource(params) {
  let data = params.data;
  if (params.data.background && params.data.background.rawFile instanceof File) {
    data.background = (await uploadImage(params.data.background))['@id']
  }
  if (params.data.headerBackground && params.data.headerBackground.rawFile instanceof File) {
    data.headerBackground = (await uploadImage(params.data.headerBackground))['@id']
  }
  if (params.data.footerBackground && params.data.footerBackground.rawFile instanceof File) {
    data.footerBackground = (await uploadImage(params.data.footerBackground))['@id']
  }
  if (params.data.scanIcon && params.data.scanIcon.rawFile instanceof File) {
    data.scanIcon = (await uploadImage(params.data.scanIcon))['@id']
  }
  if (params.data.brandingBottomLeftLogo && params.data.brandingBottomLeftLogo.rawFile instanceof File) {
    data.brandingBottomLeftLogo = (await uploadImage(params.data.brandingBottomLeftLogo))['@id']
  }
  if (params.data.brandingBottomRightLogo && params.data.brandingBottomRightLogo.rawFile instanceof File) {
    data.brandingBottomRightLogo = (await uploadImage(params.data.brandingBottomRightLogo))['@id']
  }
  if (params.data.brandingTopLeftLogo && params.data.brandingTopLeftLogo.rawFile instanceof File) {
    data.brandingTopLeftLogo = (await uploadImage(params.data.brandingTopLeftLogo))['@id']
  }
  if (params.data.brandingTopRightLogo && params.data.brandingTopRightLogo.rawFile instanceof File) {
    data.brandingTopRightLogo = (await uploadImage(params.data.brandingTopRightLogo))['@id']
  }
  return data;
}
