import {entrypoint} from "./data/dataProvider";

export function uploadImage(image, generateThumbnails = false) {
  const body = new FormData();
  body.append("file", image.rawFile);
  const params = generateThumbnails === true ? '?generate-thumbnails' : '';
  return (fetch(`${entrypoint}/images${params}`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`
    }),
    method: "POST"
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (jsondata) {
      return jsondata;
    }));
}

export function uploadVideo(video) {
  const body = new FormData();
  body.append("file", video.rawFile);
  return (fetch(`${entrypoint}/videos`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`
    }),
    method: "POST"
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (jsondata) {
      return jsondata;
    }));
}

export function uploadDocument(document, preserveOriginalName = false) {
  const body = new FormData();
  body.append("file", document.rawFile);
  return (fetch(`${entrypoint}/documents${preserveOriginalName===true ? '?preserveOriginalName' : ''}`, {
    body,
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`
    }),
    method: "POST"
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (jsondata) {
      return jsondata;
    }));
}
