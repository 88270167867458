import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {ImageInput, UrlField, TextField, ImageField, SimpleShowLayout, Show, Labeled} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import FileSizeField from "../components/fields/FileSizeField";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const ImageList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <UrlField source={"contentUrl"}/>
  </RefreshableListBySelectedProject>
);

export const ImageCreate = props => (
  <CreateGuesser {...props}>
    <ImageInput source="file" label="File" placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="name"/>
    </ImageInput>
  </CreateGuesser>
);
export const ImageEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"}/>
    <ImageField source="contentUrl" title="name" label={"Image preview"}/>
  </EditGuesser>
);

export const ImageShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source={"name"}/>
      <TextField source={"width"}/>
      <TextField source={"height"}/>
      <Labeled label="Content size">
        <FileSizeField source={"contentSize"} label={"contentSize"}/>
      </Labeled>
      <TextField source={"contentPath"}/>
      <TextField source={"encodingFormat"}/>
      <TextField source={"fileFormat"}/>
      <TextField source={"spaceKey"}/>
      <UrlField source={"contentUrl"}/>
      <ImageField source="contentUrl" title="name" label={"Image preview"}/>
    </SimpleShowLayout>
  </Show>
);
