import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  required,
  ImageInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import {parse} from "query-string";

export const ProductColorCreate = props => {
  const {context, product: productUri, product_version: productVersionUri} = parse(props.location.search);
  const encodedProductUri = encodeURIComponent(productUri);
  const encodedProductVersionUri = encodeURIComponent(productVersionUri);
  const redirect = context === 'product' ? `/products/${encodedProductUri}/colors` : productVersionUri ? `/product_versions/${encodedProductVersionUri}/colors` : false;

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{ product: productUri, productVersion: productVersionUri}}>
      <InputGuesser source={"name"} validate={[required()]}/>
      <InputGuesser source={"colorName"} validate={[required()]}/>
      <InputGuesser source={"colorValue"} validate={[required()]}/>
      <InputGuesser source={"position"} />
      <ImageInput source="image" label="Image" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>

      <ReferenceInput
        allowEmpty={true}
        source="product"
        reference="products"
        label="Product"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="productVersion"
        reference="product_versions"
        label="Product version"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const productUri = encodeURIComponent(data.product);
  return `/products/${productUri}/colors`;
};

export const ProductColorEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"colorName"} validate={[required()]}/>
    <InputGuesser source={"colorValue"} validate={[required()]}/>
    <InputGuesser source={"position"} />
    <ImageInput source="image" label="Image" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ReferenceInput
      allowEmpty={true}
      source="product"
      reference="products"
      label="Product"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="productVersion"
      reference="product_versions"
      label="Product version"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
