import {
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser,
} from "@api-platform/admin";
import React, { useState, useEffect } from "react";
import {
  required,
  SelectInput,
} from "react-admin";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const ServiceConfigList = (props) => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"serviceName"} />
    <FieldGuesser source={"provider"} />
    <FieldGuesser source={"model"} />
    <FieldGuesser source={"options"} />
    <FieldGuesser source={"VoiceClientId"} />
    <FieldGuesser source={"showyAiConfig"} />
  </RefreshableListBySelectedProject>
);
// const models = [
//     {id: 'gpt-4o-mini', name: 'gpt 4o mini'},
//     {id: 'llama3-8b', name: 'Llama3 8b'},
//     {id: 'llama3-70b', name: 'Llama3 70b'},
//     {id: 'llama3.1-405b', name: 'Llama3.1 405b'},
// ];

const voiceProviders = [
  { id: "microsoft", name: "Microsoft" },
  { id: "amazon", name: "Amazon" },
  { id: "google", name: "google" },
  { id: "afflorithmics", name: "afflorithmics" },
  { id: "elevenlabs", name: "elevenlabs" },
  { id: "playHT", name: "playHT" },
];

const toolChoices = [
  { id: "auto", name: "Auto" },
  { id: "required", name: "Required" },
];

const speechModels = [{ id: "whisper-1", name: "whisper-1" }];

export const ServiceConfigCreate = (props) => {
  const [serviceName, setServiceName] = useState("llm"); // Default to 'llm'
  const [providerChoices, setProviderChoices] = useState([]);
  const [modelChoices, setModelChoices] = useState([]);

  // Default service-provider relationships
  const serviceProviderChoices = {
    llm: [{ id: "openai", name: "OpenAi" }],
    tts: [{ id: "cartesia", name: "Cartesia" }],
    stt: [{ id: "deepgram", name: "Deepgram" }],
  };

  // Default provider-model relationships
  const providerModelChoices = {
    openai: [{ id: "gpt-4o", name: "GPT-4o" }],
    cartesia: [{ id: "sonic-multilingual", name: "Sonic Multilingual" }],
    deepgram: [{ id: "nova-2-general", name: "Nova 2 General" }],
  };

  // Update provider choices when serviceName changes
  useEffect(() => {
    setProviderChoices(serviceProviderChoices[serviceName] || []);
  }, [serviceName]);

  // Update model choices when provider changes
  const handleProviderChange = (event) => {
    const provider = event.target.value;
    setModelChoices(providerModelChoices[provider] || []);
  };

  return (
    <CreateGuesser {...props}>
      <SelectInput
        choices={[
          { id: "llm", name: "LLM" },
          { id: "tts", name: "TTS" },
          { id: "stt", name: "STT" },
        ]}
        validate={[required()]}
        source="serviceName"
        onChange={(e) => setServiceName(e.target.value)}
      />

      {/* SelectInput for Provider, updated dynamically based on serviceName */}
      <SelectInput
        choices={providerChoices}
        validate={[required()]}
        source="provider"
        onChange={handleProviderChange}
      />

      {/* SelectInput for Model, updated dynamically based on provider */}
      <SelectInput
        choices={modelChoices}
        validate={[required()]}
        source="model"
      />

      <InputGuesser source="options" />
      {serviceName === "tts" && (
        <SelectInput
          label="Voice"
          choices={[
            { id: "a249eaff-1e96-4d2c-b23b-12efa4f66f41", name: "Voice 1" },
            // Add more voice options here
          ]}
          validate={[required()]}
          source="voice"
        />
      )}
      <InputGuesser source="showyAiConfig" />
    </CreateGuesser>
  );
};
export const ServiceConfigEdit = (props) => {
  return (
    <EditGuesser {...props}>
      <InputGuesser source={"serviceName"} />
      <SelectInput
        choices={[
          { id: "llm", name: "LLM" },
          { id: "tts", name: "TTS" },
          { id: "STT", name: "STT" },
        ]}
        validate={[required()]}
        defaultValue="llm"
        source={"provider"}
      />{" "}
      <InputGuesser source={"model"} />
      <InputGuesser source={"options"} />
      <InputGuesser source={"VoiceClientId"} />
      <InputGuesser source={"showyAiConfig"} />
    </EditGuesser>
  );
};
