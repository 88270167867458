

export async function handleAiResourceResource(params) {
  let data = params.data;
  if (Array.isArray(params.data.parameters)) {
    const ps = [];
    for(let p of params.data.parameters) {
      ps.push(p['@id']);
    }
    data.parameters = ps;
  }
  return data;
}
