import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React, {useEffect} from "react";
import {
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceManyField,
  TextField,
  ShowButton,
  EditButton
} from "react-admin";
import AddCategoryButton from "./addCategoryButton";
import {useAtom} from "jotai";
import {selectedProjectAtom} from "../atom/project";
import { useRefresh } from 'react-admin';
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const QuizList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
  </RefreshableListBySelectedProject>
);

export const QuizCreate = props => {
  const refresh = useRefresh();
  const [project] = useAtom(selectedProjectAtom);

  useEffect(() => {
    console.log({device_project: project})
    refresh();
  }, [project]);

  return (
      <CreateGuesser {...props}>
        <InputGuesser source="name"/>
      </CreateGuesser>
  )
};

export const QuizEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>


      </Tab>
      <Tab label="Categories" path="categories">
        <ReferenceManyField
          addLabel={false}
          reference="quiz_question_categories"
          target="quiz">
          <Datagrid>
            <TextField source="name"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddCategoryButton/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);

