import React from 'react';
import PropTypes from 'prop-types';

const fetchHeaders = () => {
  return {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    'content-type': 'application/ld+json'
  }
};

class CustomVideoField extends React.Component {
  state = {
    vidUrl: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.record !== this.props.record || prevProps.source !== this.props.source) {
      this.handleVideo();
    }
  }

  componentDidMount() {
    this.handleVideo();
  }

  handleVideo() {
    const {record, source} = this.props;
    if (record.rawFile instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(record.rawFile);
      reader.onload = () => this.setState({vidUrl: reader.result});
      reader.onerror = err => console.log(err);
    } else if (typeof record === 'string' || typeof record[source] === 'string') {
      const vidIri = typeof record === 'string' ? record : record[source];
      if (-1 !== vidIri.indexOf('http')) {
        this.setState({vidUrl: vidIri});
      } else {
        fetch(process.env.REACT_APP_API_ENTRYPOINT + vidIri, {headers: new Headers(fetchHeaders())})
          .then((response) => {
            response.json().then(data => {
              // TODO: use another property like src to set the property
              if (typeof data[source] === 'undefined') {
                this.setState({vidUrl: data.contentUrl});
              } else {
                this.setState({vidUrl: data[source]});
              }
            })
          });
      }
    } else {
      if (record[source] !== null) {
        this.setState({vidUrl: record[source]});
      }
    }
  }

  render() {
    const {vidUrl} = this.state;
    console.log({vidUrl})
    return (
      <div>
        {vidUrl !== null && (
          <video src={vidUrl} style={{width: '150px', maxWidth: '150px'}} controls/>
        )}
      </div>
    );
  }
}

CustomVideoField.propTypes = {
  label: PropTypes.string,
  //record: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default CustomVideoField;
