import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ReferenceManyField,
  Tab,
  TabbedShowLayout,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import AddConfiguratorItemButton from "./addConfiguratorItemButton";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const ConfiguratorConfigList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
  </RefreshableListBySelectedProject>
);

export const ConfiguratorConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"mainBackgroundColor"}/>

    <ImageInput source="brandLogo" label="Brand Logo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <ImageInput source="mainBackgroundImage" label="Background image" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"scanMessage"}/>

    <ImageInput source="scanIcon" label="Scan Icon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"testDriveRequestEnabled"}/>
    <InputGuesser source={"navigateByBrandsEnabled"}/>
    <InputGuesser source={"navigateByModelsEnabled"}/>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      filter={{ 'application.slug': 'configurator'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>
  </CreateGuesser>
);
export const ConfiguratorConfigEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} validate={[required()]}/>
        <InputGuesser source={"mainBackgroundColor"}/>

        <ImageInput source="brandLogo" label="Brand Logo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="mainBackgroundImage" label="Background image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"scanMessage"}/>

        <ImageInput source="scanIcon" label="Scan Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"testDriveRequestEnabled"}/>
        <InputGuesser source={"navigateByBrandsEnabled"}/>
        <InputGuesser source={"navigateByModelsEnabled"}/>

        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={searchText => ({name: searchText})}
          filter={{ 'application.slug': 'configurator'}}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>


      </Tab>
      <Tab label="Items" path="items">
        <ReferenceManyField
          addLabel={false}
          reference="configurator_items"
          target="configuratorConfig">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="type"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddConfiguratorItemButton />
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
