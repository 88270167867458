export const roleHierarchy = {
  'ROLE_SUPER_ADMIN': [
    'ROLE_ADMIN',
    'ROLE_APP',
    'ROLE_DEVICE',
    'ROLE_USER',
  ],
  'ROLE_ADMIN': [
    'ROLE_APP',
    'ROLE_DEVICE',
    'ROLE_USER',
  ],
  'ROLE_DEVICE': [
    'ROLE_USER',
  ],
  'ROLE_APP': [
    'ROLE_USER',
  ],
  'ROLE_BRAND_MANAGER': [
    'ROLE_USER',
  ],
  'ROLE_PROJECT_MANAGER': [
    'ROLE_BRAND_MANAGER',
    'ROLE_USER',
  ],
  'ROLE_USER': []
};

const isGranted = (role, permissions) => {
  if(!permissions) return false;
  if (permissions.includes(role)) {
    return true;
  }
  if (typeof roleHierarchy[role] !== 'undefined') {
    for (let userRole of permissions.split(',')) {
      for (let r of roleHierarchy[userRole]) {
        if (r === role) {
          return true;
        }
      }
    }
  }
  return false;
}

export default isGranted;
