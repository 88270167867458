import React, {Fragment} from 'react';
import PropTypes from 'prop-types';


const fetchHeaders = () => {
  return {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    'content-type': 'application/ld+json'
  }
};

class CustomMediaField extends React.Component {
  state = {
    mediaUrl: null,
    mediaType: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.record !== this.props.record || prevProps.source !== this.props.source) {
      this.handleMedia();
    }
  }

  componentDidMount() {
    this.handleMedia();
  }

  handleMedia() {
    const {record} = this.props;
    const mediaIri = record.image ? record.image : record.video;

    fetch(process.env.REACT_APP_API_ENTRYPOINT + mediaIri, {headers: new Headers(fetchHeaders())})
      .then((response) => {
        response.json().then(data => {
          this.setState({mediaUrl: data.contentUrl, mediaType: data['@type']});
        })
      });
  }

  render() {
    const {mediaUrl, mediaType} = this.state;
    //console.log(mediaType.toLowerCase().contains('image'))
    if (mediaUrl === null) {
      return <Fragment/>;
    }
    console.log()
    return (
      <div>
        {mediaType.toLowerCase().indexOf('image') !== -1 ?
          <img src={mediaUrl} style={{height: '100px', maxHeight: '100px'}} alt=""/>
          : <video src={mediaUrl} style={{height: '100px', maxHeight: '100px'}} controls/>}
      </div>
    );
  }
}

CustomMediaField.propTypes = {
  label: PropTypes.string,
  //record: PropTypes.string,
  //source: PropTypes.string.isRequired,
};

export default CustomMediaField;
