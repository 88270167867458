import {uploadImage} from "../mediaUploader";


export async function handleAccountResource(params) {
  const data = params.data;
  if (params.data.logo) {
    if (params.data.logo.rawFile instanceof File) {
      data.logo = (await uploadImage(params.data.logo))['@id'];
    } else if (typeof data.logo === 'object') {
      data.logo = params.data.logo['@id'];
    }
  }
  return data;
}
