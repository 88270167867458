import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React, {useEffect} from "react";
import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
    TabbedShowLayout,
    Tab,
    ReferenceManyField,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    NumberField,
    FormDataConsumer,
} from "react-admin";

import AddFormItemButton from './addFormItemButton';
import AddFormStepButton from './addFormStepButton';
import RichTextInput from "ra-input-rich-text";
import {useAtom} from "jotai";
import {selectedProjectAtom} from "../atom/project";
import {useRefresh} from 'react-admin';
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const FormList = props => {
    const refresh = useRefresh();
    const [project] = useAtom(selectedProjectAtom);

    useEffect(() => {
        console.log({device_project: project})
        refresh();
    }, [project]);

    return (
        <RefreshableListBySelectedProject {...props}>
            <FieldGuesser source={"name"}/>
        </RefreshableListBySelectedProject>
    )
};

export const FormCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"name"}/>
        <InputGuesser source={"title"}/>
        <InputGuesser source={"subTitle"}/>
        <InputGuesser source={"isMultiStepForm"}/>
        <InputGuesser source={"submitButtonLabel"}/>
        <InputGuesser source={"legalNoticesEnabled"}/>
        <InputGuesser source={"legalNoticesLabel"}/>
        <RichTextInput source="legalNoticesContent"
                       toolbar={[[{'header': [1, 2, 3, 4, 5, 6, false]}], ['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <ReferenceArrayInput
            source="checkInConfigs"
            reference="check_in_configs"
            label="CheckInConfigs"
            filterToQuery={searchText => ({name: searchText})}
        >
            <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput> </CreateGuesser>
);

export const FormEdit = props => (
    <EditGuesser {...props}>
        <TabbedShowLayout fullWidth={true}>
            <Tab label="Summary">
                <InputGuesser source={"name"}/>
                <InputGuesser source={"title"}/>
                <InputGuesser source={"subTitle"}/>
                <InputGuesser source={"isMultiStepForm"}/>
                <InputGuesser source={"submitButtonLabel"}/>
                <InputGuesser source={"legalNoticesEnabled"}/>
                <InputGuesser source={"legalNoticesLabel"}/>
                <RichTextInput source="legalNoticesContent"
                               toolbar={[[{'header': [1, 2, 3, 4, 5, 6, false]}], ['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
                <ReferenceArrayInput
                    source="checkInConfigs"
                    reference="check_in_configs"
                    label="CheckInConfigs"
                    filterToQuery={searchText => ({name: searchText})}
                >
                    <AutocompleteArrayInput optionText="name"/>
                </ReferenceArrayInput>

            </Tab>
            <FormDataConsumer>
                {({formData, ...rest}) => !formData.isMultiStepForm &&
                    <Tab label="Form Items" path="items" {...rest}>
                        <ReferenceManyField
                            addLabel={false}
                            reference="form_items"
                            target="form">
                            <Datagrid>
                                <TextField source="label"/>
                                <TextField source="name"/>
                                <TextField source="type"/>
                                <FieldGuesser source="required"/>
                                <ShowButton/>
                                <EditButton/>
                            </Datagrid>
                        </ReferenceManyField>
                        <AddFormItemButton context={'form'}/>
                    </Tab>
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({formData, ...rest}) => formData.isMultiStepForm &&
                    <Tab label="Form Steps" path="steps" {...rest}>
                        <ReferenceManyField
                            addLabel={false}
                            reference="form_steps"
                            target="form"
                        >
                            <Datagrid>
                                <TextField source="title"/>
                                <TextField source="subtitle"/>
                                <NumberField source="order"/>
                                <ShowButton/>
                                <EditButton/>
                            </Datagrid>
                        </ReferenceManyField>
                        <AddFormStepButton/>
                    </Tab>
                }
            </FormDataConsumer>
        </TabbedShowLayout>
    </EditGuesser>
);
