import {
  FieldGuesser,
  InputGuesser,
  EditGuesser,
} from "@api-platform/admin";
import React from "react";
import {
  Create,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ImageInput,
  FileInput,
  required,
  minLength,
  TabbedForm,
  FormTab,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  TabbedShowLayout,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import RichTextInput from "ra-input-rich-text";
import AddConfigurationItemButton from "./addConfigurationItemButton";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const CheckInConfigList = (props) => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"scanRequired"} />
    <FieldGuesser source={"scanType"} />
    <FieldGuesser source={"checkOutEnabled"} />
  </RefreshableListBySelectedProject>
);

export const CheckInConfigCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Main">
        <InputGuesser source={"name"} />
        <InputGuesser
          source={"mainBackgroundColor"}
          validate={[minLength(4)]}
        />
        <InputGuesser source={"titleColor"} validate={[minLength(4)]} />
        <InputGuesser source={"subTitleColor"} validate={[minLength(4)]} />
        <InputGuesser source={"mainColor"} validate={[minLength(4)]} />
        <ImageInput
          source="mainBackground"
          label="MainBackground"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <ImageInput
          source="headerBackground"
          label="HeaderBackground"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <ImageInput
          source="footerBackground"
          label="FooterBackground"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <ImageInput
          source="scanIcon"
          label="ScanIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <InputGuesser source={"scanMessage"} multiline fullWidth />
        <ImageInput
          source="successIcon"
          label="SuccessIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <RichTextInput
          source="successTitle"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <RichTextInput
          source="successSubTitle"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <InputGuesser source={"buttonColor"} validate={[minLength(4)]} />
        <InputGuesser source={"buttonBgColor"} validate={[minLength(4)]} />
        <InputGuesser source={"inputColor"} validate={[minLength(4)]} />

        <ImageInput
          source="submitButtonBackground"
          label="submitButtonBackground"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <InputGuesser
          source={"inputBackgroundColor"}
          validate={[minLength(4)]}
        />
        <InputGuesser
          source={"inputFocusBackgroundColor"}
          validate={[minLength(4)]}
        />
        <ImageInput
          source="inputBg"
          label="InputBg"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>

        <InputGuesser source={"inputBorderRadius"} />
        <InputGuesser source={"closeButtonLabel"} />
        <InputGuesser source={"closeButtonColor"} validate={[minLength(4)]} />

        <ImageInput
          source="closeButtonIcon"
          label="CloseButtonIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>

        <ImageInput
          source="appointmentButtonIcon"
          label="AppointmentButtonIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>

        <ImageInput
          source="formBackground"
          label="Form Background"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>

        <ReferenceInput
          allowEmpty={true}
          source="form"
          reference="forms"
          label="Form"
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="checkOutForm"
          reference="forms"
          label="Check Out Form"
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <InputGuesser source={"scanRequired"} />
        <SelectInput
          label="ScanType"
          source="scanType"
          initialValue={"nfc"}
          choices={[
            { id: "qrcode", name: "QRCode" },
            { id: "nfc", name: "NFC" },
          ]}
        />
        <InputGuesser source={"appointmentEnabled"} />
        <InputGuesser source={"hasInvitedPeople"} />
        <InputGuesser source={"requireFormReview"} />
        <InputGuesser source={"searchEnabled"} />

        <ReferenceInput
          allowEmpty={true}
          source="searchableFieldItem"
          reference="form_items"
          label="SearchableFieldItem"
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <InputGuesser source={"requireCurrentVehicleModel"} />
        <InputGuesser source={"currentVehicleBrandFormLabel"} />
        <InputGuesser source={"currentVehicleBrandFormEmptyValue"} />
        <InputGuesser source={"currentVehicleModelFormLabel"} />
        <InputGuesser source={"currentVehicleModelFormEmptyValue"} />
      </FormTab>

      <FormTab label="Check Out">
        <InputGuesser source={"checkOutEnabled"} />
        <RichTextInput
          source="appRoleTitle"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <RichTextInput
          source="appRoleSubtitle"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <ImageInput
          source="checkInIcon"
          label="checkInIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <InputGuesser source={"checkInButtonLabel"} multiline fullWidth />
        <InputGuesser source={"checkOutButtonLabel"} multiline fullWidth />
        <RichTextInput
          source="checkOutScanMessage"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <RichTextInput
          source="checkOutSuccessMessage"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <InputGuesser source={"checkInHeaderTitle"} multiline fullWidth />
        <InputGuesser source={"appRoleColor"} />
      </FormTab>

      <FormTab label="Ui style">
        <FileInput
          source="font"
          label="Font"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomDocumentField source="name" />
        </FileInput>
        <InputGuesser source={"cssStyle"} multiline fullWidth />
      </FormTab>

      <FormTab label="Relations">
        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={(searchText) => ({ name: searchText })}
          filter={{ "application.slug": "check-in" }}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
export const CheckInConfigEdit = (props) => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} />
        <InputGuesser
          source={"mainBackgroundColor"}
          validate={[minLength(4)]}
        />
        <InputGuesser source={"titleColor"} validate={[minLength(4)]} />
        <InputGuesser source={"subTitleColor"} validate={[minLength(4)]} />
        <InputGuesser source={"mainColor"} validate={[minLength(4)]} />
        <ImageInput
          source="mainBackground"
          label="MainBackground"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <ImageInput
          source="headerBackground"
          label="HeaderBackground"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <ImageInput
          source="footerBackground"
          label="FooterBackground"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <ImageInput
          source="scanIcon"
          label="ScanIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <RichTextInput
          source="scanMessage"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <ImageInput
          source="successIcon"
          label="SuccessIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <RichTextInput
          source="successTitle"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <RichTextInput
          source="successSubTitle"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <InputGuesser source={"buttonColor"} validate={[minLength(4)]} />
        <InputGuesser source={"buttonBgColor"} validate={[minLength(4)]} />
        <InputGuesser source={"inputColor"} validate={[minLength(4)]} />

        <ImageInput
          source="submitButtonBackground"
          label="submitButtonBackground"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <InputGuesser
          source={"inputBackgroundColor"}
          validate={[minLength(4)]}
        />
        <InputGuesser
          source={"inputFocusBackgroundColor"}
          validate={[minLength(4)]}
        />
        <ImageInput
          source="inputBg"
          label="InputBg"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>

        <InputGuesser source={"inputBorderRadius"} />
        <InputGuesser source={"closeButtonLabel"} />
        <InputGuesser source={"closeButtonColor"} validate={[minLength(4)]} />
        <ImageInput
          source="closeButtonIcon"
          label="CloseButtonIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>

        <ImageInput
          source="appointmentButtonIcon"
          label="Appointment Button Icon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>

        <ImageInput
          source="formBackground"
          label="Form Background"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>

        <ReferenceInput
          allowEmpty={true}
          source="form"
          reference="forms"
          label="Form"
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="checkOutForm"
          reference="forms"
          label="Check Out Form"
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <InputGuesser source={"scanRequired"} />
        <SelectInput
          label="ScanType"
          source="scanType"
          choices={[
            { id: "qrcode", name: "QRCode" },
            { id: "nfc", name: "NFC" },
          ]}
          validate={[required()]}
        />
        <InputGuesser source={"appointmentEnabled"} />
        <InputGuesser source={"hasInvitedPeople"} />
        <InputGuesser source={"requireFormReview"} />
        <InputGuesser source={"searchEnabled"} />

        <ReferenceInput
          allowEmpty={true}
          source="searchableFieldItem"
          reference="form_items"
          label="SearchableFieldItem"
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <InputGuesser source={"requireCurrentVehicleModel"} />
        <InputGuesser source={"currentVehicleBrandFormLabel"} />
        <InputGuesser source={"currentVehicleBrandFormEmptyValue"} />
        <InputGuesser source={"currentVehicleModelFormLabel"} />
        <InputGuesser source={"currentVehicleModelFormEmptyValue"} />
        <InputGuesser source={"checkOutEnabled"} />
        <RichTextInput
          source="appRoleTitle"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <RichTextInput
          source="appRoleSubtitle"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <ImageInput
          source="checkInIcon"
          label="checkInIcon"
          accept="image/*"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomImageField source="contentUrl" />
        </ImageInput>
        <InputGuesser source={"checkInButtonLabel"} multiline fullWidth />
        <InputGuesser source={"checkOutButtonLabel"} multiline fullWidth />
        <RichTextInput
          source="checkOutScanMessage"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <RichTextInput
          source="checkOutSuccessMessage"
          toolbar={[
            ["bold", "italic", "underline", "link", "clean"],
            [{ direction: "rtl" }],
          ]}
        />
        <InputGuesser source={"checkInHeaderTitle"} multiline fullWidth />
        <InputGuesser source={"appRoleColor"} />
        <FileInput
          source="font"
          label="Font"
          placeholder={<p>Drop your file here</p>}
        >
          <CustomDocumentField source="name" />
        </FileInput>
        <InputGuesser source={"cssStyle"} multiline={true} fullWidth={true} />

        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={(searchText) => ({ name: searchText })}
          filter={{ "application.slug": "check-in" }}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </Tab>
      <Tab label="Configurations" path="configurations">
        <ReferenceManyField
          addLabel={false}
          reference="configuration_item_values"
          target="checkInConfig"
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="type" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <AddConfigurationItemButton context={"checkInConfig"} />
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
