import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React, {useEffect} from "react";
import {
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import AddDeviceButton from "../device/addDeviceButton";
import {useAtom} from "jotai";
import {selectedProjectAtom} from "../atom/project";
import { useRefresh } from 'react-admin';
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const DeviceCircleList = props => {
    const refresh = useRefresh();
    const [project] = useAtom(selectedProjectAtom);

    useEffect(() => {
        console.log({device_project: project})
        refresh();
    }, [project]);

    return (
        <RefreshableListBySelectedProject {...props}>
            <FieldGuesser source={"name"}/>
            <FieldGuesser source={"slug"}/>
        </RefreshableListBySelectedProject>
    )
};

export const DeviceCircleCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>

    <ReferenceInput
      allowEmpty={true}
      source="brand"
      reference="brands"
      label="Brand"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const DeviceCircleEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>



        <ReferenceInput
          allowEmpty={true}
          source="brand"
          reference="brands"
          label="Brand"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Devices" path="devices">
        <ReferenceManyField
          addLabel={false}
          reference="devices"
          target="deviceCircle">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="deviceUID"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddDeviceButton/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
