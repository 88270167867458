import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  required,
} from "react-admin";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const MediaPlayerConfigList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"playList"}/>
    <FieldGuesser source={"project"}/>
  </RefreshableListBySelectedProject>
);

export const MediaPlayerConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"}/>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      filter={{'application.slug': 'media-player'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={true}
      source="playList"
      reference="play_lists"
      label="PlayList"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);
export const MediaPlayerConfigEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"}/>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
      filter={{'application.slug': 'media-player'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={true}
      source="playList"
      reference="play_lists"
      label="PlayList"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
