import {uploadDocument, uploadImage} from "../mediaUploader";

export async function handleCheckOutConfigResource(params) {
  let data = params.data;
  if (params.data.mainBackground && params.data.mainBackground.rawFile instanceof File) {
    data.mainBackground = (await uploadImage(params.data.mainBackground))['@id'];
  }
  if (params.data.headerBackground && params.data.headerBackground.rawFile instanceof File) {
    data.headerBackground = (await uploadImage(params.data.headerBackground))['@id'];
  }
  if (params.data.footerBackground && params.data.footerBackground.rawFile instanceof File) {
    data.footerBackground = (await uploadImage(params.data.footerBackground))['@id'];
  }
  if (params.data.scanIcon && params.data.scanIcon.rawFile instanceof File) {
    data.scanIcon = (await uploadImage(params.data.scanIcon))['@id'];
  }
  if (params.data.successIcon && params.data.successIcon.rawFile instanceof File) {
    data.successIcon = (await uploadImage(params.data.successIcon))['@id'];
  }
  if (params.data.closeButtonIcon && params.data.closeButtonIcon.rawFile instanceof File) {
    data.closeButtonIcon = (await uploadImage(params.data.successIcon))['@id'];
  }
  if (params.data.font && params.data.font.rawFile instanceof File) {
    data.font = (await uploadDocument(params.data.font))['@id'];
  }
  return data;
}
