import React from 'react';
import {Link} from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {withStyles} from '@material-ui/core/styles';
import {Button} from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddProductVariantButton = ({classes, record}) => {
  return (
    <Button
      className={classes.button}
      variant="text"
      component={Link}
      to={`/product_variants/create?product=${record['@id']}`}
      label="Add a Variant"
      title="Add a Variant"
    >
      <AddBoxIcon/>
    </Button>
  )
};

export default withStyles(styles)(AddProductVariantButton);
