import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import CustomImageField from "./fields/CustomImageField";
import {ImageInput, usePermissions} from "react-admin";
import isGranted from "../security";
import { Redirect } from 'react-router';

export const AccountList = props => {
  const {loaded, permissions} = usePermissions();
  if (!loaded) {
    return null;
  }
  if (!isGranted('ROLE_SUPER_ADMIN', permissions)) {
    return (<Redirect to="/projects" />);
  }
  return (
    <ListGuesser {...props}>
      <FieldGuesser source={"name"}/>
    </ListGuesser>
  );
}

export const AccountCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>
    <ImageInput source="logo" label="Logo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
  </CreateGuesser>
);

export const AccountEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name"/>
    <ImageInput source="logo" label="Logo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
  </EditGuesser>
);
