import {uploadImage, uploadVideo} from "../mediaUploader";

export async function handlePlayListItemResource(params) {
  let data = params.data;
  if (params.data.image) {
    if (params.data.image.rawFile instanceof File) {
      data.image = (await uploadImage(params.data.image))['@id'];
    } else if (typeof data.image === 'object') {
      data.image = params.data.image['@id']
    }
  }

  if (params.data.video) {
    if (params.data.video.rawFile instanceof File) {
      data.video = (await uploadVideo(params.data.video))['@id'];
    } else if (typeof data.video === 'object') {
      data.video = params.data.video['@id']
    }
  }

  return data;
}
