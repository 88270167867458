import React from 'react';
import PropTypes from 'prop-types';

const HasMediaTypeField = ({record = {}}) => {
  const mediaType = record.video ? 'Video' : record.document ? 'Document' : record.image ? 'Image' : null;
  return (<span>{mediaType}</span>);
};

HasMediaTypeField.propTypes = {
  label: PropTypes.string,
};

export default HasMediaTypeField;
