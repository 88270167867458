import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import RichTextInput from "ra-input-rich-text";

export const ApplicationList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
  </ListGuesser>
);
export const ApplicationCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>
    <RichTextInput source="description"/>
  </CreateGuesser>
);

export const ApplicationEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name"/>
    <RichTextInput source="description"/>
  </EditGuesser>
);
