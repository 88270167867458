import React from 'react';
import { Link } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddAttributeValueButton = ({ classes, record }) => {
  console.log({comparableItemRecord: record})
  return (
      <Button
          className={classes.button}
          variant="contained"
          component={Link}
          to={`/attribute_values/create?comparableItem=${record['@id']}&comparatorConfig=${record.comparatorConfig}`}
          label="Add an attribute value"
          title="Add an attribute value"
      >
        <AddBoxIcon />
      </Button>
  );
}

export default withStyles(styles)(AddAttributeValueButton);
