import * as React from "react";
import PropTypes from 'prop-types';
import {useRecordContext} from 'react-admin';

const FileSizeField = (props) => {
  const {source} = props;
  const record = useRecordContext(props);
  const contentSize = record ? (record[source] ? ((record[source] / 1024 / 1024).toFixed(2)) + ' mb' : '') : '';
  return <span>{contentSize}</span>;
}

FileSizeField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  addLabel: true,
};

export default FileSizeField;
