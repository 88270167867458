import React from 'react';
import PropTypes from 'prop-types';

const fetchHeaders = () => {
  return {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    'content-type': 'application/ld+json'
  }
};

class CustomDocumentField extends React.Component {
  state = {
    docName: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.record !== this.props.record || prevProps.source !== this.props.source) {
      this.handleDocument();
    }
  }

  componentDidMount() {
    this.handleDocument();
  }

  handleDocument() {
    const {record, source} = this.props;
    if (record.rawFile instanceof File) {
      this.setState({docName: record.rawFile.name});
    } else if (typeof record === 'string' || typeof record[source] === 'string') {
      const docIRI = typeof record === 'string' ? record : record[source];
      if (docIRI.indexOf('/') !== 0) {
        this.setState({docName: docIRI});
        return;
      }
      fetch(process.env.REACT_APP_API_ENTRYPOINT + docIRI, {headers: new Headers(fetchHeaders())})
        .then((response) => {
          response.json().then(data => {
            // TODO: use another property like src to set the property
            if (typeof data[source] === 'undefined') {
              this.setState({docName: data.name});
            } else {
              this.setState({docName: data[source]});
            }
          })
        });
    } else {
      if (record[source] !== null) {
        this.setState({docName: record[source]});
      }
    }
  }

  render() {
    const {docName} = this.state;
    return (
      <div>
        {docName !== null && (
          <span>{docName}</span>
        )}
      </div>
    );
  }
}

CustomDocumentField.propTypes = {
  label: PropTypes.string,
  //record: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default CustomDocumentField;
