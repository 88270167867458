import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import CustomImageField from "../components/fields/CustomImageField";
import {
  TabbedShowLayout,
  Tab,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ImageInput,
  ReferenceManyField,
} from "react-admin";
import AddProductButton from "./addProductButton";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const BrandList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
  </RefreshableListBySelectedProject>
);

export const BrandCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>
    <ImageInput source="logo" label="Logo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
  </CreateGuesser>
);

export const BrandEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>
        <ImageInput source="logo" label="Logo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>
      </Tab>
      <Tab label="Products" path="products">
        <ReferenceManyField
          addLabel={false}
          reference="products"
          target="brand">
          <Datagrid>
            <TextField source="name"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddProductButton/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
