import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
  required,
  SelectInput,
  FileInput,
  FormDataConsumer,
  NumberInput,
  TextInput,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import {parse} from "query-string";
import RichTextInput from "ra-input-rich-text";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const ConfigurationItemValueList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"type"}/>
  </RefreshableListBySelectedProject>
);

export const ConfigurationItemValueCreate = props => {
  const {
    context,
    checkInConfig: checkInConfigUri,
    bulletTimeConfig: bulletTimeConfigUri,
    contentWallConfig: contentWallConfigUri,
    photoBoothConfig: photoBoothConfigUri,
    boothConfig: boothConfigUri,
  } = parse(props.location.search);
  const encodedCheckInConfigUri = encodeURIComponent(checkInConfigUri);
  const encodedBulletTimeConfigUri = encodeURIComponent(bulletTimeConfigUri);
  const encodedContentWallConfigUri = encodeURIComponent(contentWallConfigUri);
  const encodedPhotoBoothConfigUri = encodeURIComponent(photoBoothConfigUri);
  const encodedBoothConfigUri = encodeURIComponent(boothConfigUri);

  let redirect = false;
  switch (context) {
    case 'checkInConfig':
      redirect = `/check_in_configs/${encodedCheckInConfigUri}/configurations`;
      break;
    case 'bulletTimeConfig':
      redirect = `/bullet_time_configs/${encodedBulletTimeConfigUri}/configurations`;
      break;
    case 'contentWallConfig':
      redirect = `/content_wall_configs/${encodedContentWallConfigUri}/configurations`;
      break;
    case 'photoBoothConfig':
      redirect = `/content_wall_configs/${encodedPhotoBoothConfigUri}/configurations`;
      break;
    case 'boothConfig':
      redirect = `/booth_configs/${encodedBoothConfigUri}/configurations`;
      break;
    default:
      break;
  }

  return (
    <CreateGuesser {...props} redirect={redirect}
                   initialValues={{
                     checkInConfig: checkInConfigUri,
                     bulletTimeConfig: bulletTimeConfigUri,
                     contentWallConfig: contentWallConfigUri,
                     photoBoothConfig: photoBoothConfigUri,
                     boothConfig: boothConfigUri,
                   }}>
      <InputGuesser source={"name"} validate={[required()]}/>
      <SelectInput source="type" initialValue={'text'} choices={[
        {id: 'text', name: 'Text'},
        {id: 'css', name: 'CSS'},
        {id: 'short_text', name: 'Short text'},
        {id: 'number', name: 'Number'},
        {id: 'image', name: 'Image'},
        {id: 'video', name: 'Video'},
        {id: 'document', name: 'Document'},
      ]} validate={[required()]}/>
      <FormDataConsumer>
        {({formData}) => formData.type === 'number' &&
          <NumberInput source={"value"} validate={[required()]}/>
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({formData}) => formData.type === 'short_text' &&
          <TextInput source={"value"} fullWidth validate={[required()]}/>
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({formData}) => (formData.type === 'text' || formData.type === 'css') &&
          <RichTextInput source="value" theme={'snow'}
                         toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}
                         validate={[required()]}/>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.type === 'image' &&
          <ImageInput source="image" label="Image" accept="image/*"
                      placeholder={<p>Drop your file here</p>}
                      validate={[required()]}>
            <CustomImageField source="contentUrl"/>
          </ImageInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.type === 'video' &&
          <FileInput source="video" label="Video" placeholder={<p>Drop your file here</p>} validate={[required()]}>
            <CustomDocumentField source="name"/>
          </FileInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.type === 'document' &&
          <FileInput source="document" label="Document" placeholder={<p>Drop your file here</p>}
                     validate={[required()]}>
            <CustomDocumentField source="name"/>
          </FileInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.checkInConfig &&
          <ReferenceInput
            allowEmpty={true}
            source="checkInConfig"
            reference="check_in_configs"
            label="checkInConfig"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.contentWallConfig &&
          <ReferenceInput
            allowEmpty={true}
            source="contentWallConfig"
            reference="content_wall_configs"
            label="contentWallConfig"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.comparatorConfig &&
          <ReferenceInput
            allowEmpty={true}
            source="comparatorConfig"
            reference="comparator_configs"
            label="comparatorConfig"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.configuratorConfig &&
          <ReferenceInput
            allowEmpty={true}
            source="configuratorConfig"
            reference="configurator_configs"
            label="configuratorConfig"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.bulletTimeConfig &&
          <ReferenceInput
            allowEmpty={true}
            source="bulletTimeConfig"
            reference="bullet_time_configs"
            label="bulletTimeConfig"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.photoBoothConfig &&
          <ReferenceInput
            allowEmpty={true}
            source="photoBoothConfig"
            reference="photo_booth_configs"
            label="photoBoothConfig"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({formData}) => formData.boothConfig &&
          <ReferenceInput
            allowEmpty={true}
            source="boothConfig"
            reference="booth_configs"
            label="boothConfig"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        }
      </FormDataConsumer>

    </CreateGuesser>
  );
}
export const ConfigurationItemValueEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <SelectInput source="type" initialValue={'text'} choices={[
      {id: 'text', name: 'Text'},
      {id: 'css', name: 'CSS'},
      {id: 'short_text', name: 'Short text'},
      {id: 'number', name: 'Number'},
      {id: 'image', name: 'Image'},
      {id: 'video', name: 'Video'},
      {id: 'document', name: 'Document'},
    ]} validate={[required()]}/>
    <FormDataConsumer>
      {({formData}) => formData.type === 'number' &&
        <NumberInput source={"value"} validate={[required()]}/>
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({formData}) => formData.type === 'short_text' &&
        <TextInput source={"value"} fullWidth validate={[required()]}/>
      }
    </FormDataConsumer>
    <FormDataConsumer>
      {({formData}) => (formData.type === 'text' || formData.type === 'css') &&
        <RichTextInput source="value" theme={'snow'}
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}
                       validate={[required()]}/>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.type === 'image' &&
        <ImageInput source="image" label="Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}
                    validate={[required()]}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.type === 'video' &&
        <FileInput source="video" label="Video" placeholder={<p>Drop your file here</p>} validate={[required()]}>
          <CustomDocumentField source="name"/>
        </FileInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.type === 'document' &&
        <FileInput source="document" label="Document" placeholder={<p>Drop your file here</p>}
                   validate={[required()]}>
          <CustomDocumentField source="name"/>
        </FileInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.checkInConfig &&
        <ReferenceInput
          allowEmpty={true}
          source="checkInConfig"
          reference="check_in_configs"
          label="checkInConfig"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.contentWallConfig &&
        <ReferenceInput
          allowEmpty={true}
          source="contentWallConfig"
          reference="content_wall_configs"
          label="contentWallConfig"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.comparatorConfig &&
        <ReferenceInput
          allowEmpty={true}
          source="comparatorConfig"
          reference="comparator_configs"
          label="comparatorConfig"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.configuratorConfig &&
        <ReferenceInput
          allowEmpty={true}
          source="configuratorConfig"
          reference="configurator_configs"
          label="configuratorConfig"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.bulletTimeConfig &&
        <ReferenceInput
          allowEmpty={true}
          source="bulletTimeConfig"
          reference="bullet_time_configs"
          label="bulletTimeConfig"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.photoBoothConfig &&
        <ReferenceInput
          allowEmpty={true}
          source="photoBoothConfig"
          reference="photo_booth_configs"
          label="photoBoothConfig"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      }
    </FormDataConsumer>

    <FormDataConsumer>
      {({formData}) => formData.boothConfig &&
        <ReferenceInput
          allowEmpty={true}
          source="boothConfig"
          reference="booth_configs"
          label="boothConfig"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      }
    </FormDataConsumer>
  </EditGuesser>
);
