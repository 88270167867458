import * as React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FilterList, FilterListItem } from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  startOfYesterday
} from 'date-fns';


export const LatestFilter = () => (
  <FilterList label="Latest" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Today"
      value={{
        "createdAt[strictly_after]": endOfYesterday().toISOString(),
        "createdAt[before]": undefined,
      }}
    />
    <FilterListItem
      label="Yesterday"
      value={{
        "createdAt[after]": startOfYesterday().toISOString(),
        "createdAt[before]": endOfYesterday().toISOString(),
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        "createdAt[after]": startOfWeek(new Date()).toISOString(),
        "createdAt[before]": undefined,
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        "createdAt[after]": subWeeks(startOfWeek(new Date()), 1).toISOString(),
        "createdAt[before]": startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        "createdAt[after]": startOfMonth(new Date()).toISOString(),
        "createdAt[before]": undefined,
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        "createdAt[after]": subMonths(startOfMonth(new Date()),1).toISOString(),
        "createdAt[before]": startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        "createdAt[after]": undefined,
        "createdAt[before]": subMonths(startOfMonth(new Date()),1).toISOString(),
      }}
    />
  </FilterList>
);
