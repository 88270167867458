import React from 'react';
import { Link } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddAttributeButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={`/attributes/create?comparatorConfig=${record['@id']}`}
    label="Add an attribute"
    title="Add an attribute"
  >
    <AddBoxIcon />
  </Button>
);

export default withStyles(styles)(AddAttributeButton);
