import React from "react";
import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import {required, ImageInput, SelectInput} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import {parse} from "query-string";

const attributeTypes = [
    {id: 'single_value', name: 'Single Value'},
    {id: 'multiple_values', name: 'Multiple Values'},
    {id: 'colors', name: 'Colors'},
];

export const AttributeCreate = props => {
    let {comparatorConfig: comparatorConfigUri} = parse(props.location.search);
    const encodedComparatorConfigUri = encodeURIComponent(comparatorConfigUri);
    const redirect = comparatorConfigUri ? `/comparator_configs/${encodedComparatorConfigUri}/attributes` : false;

    return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{comparatorConfig: comparatorConfigUri}}>
        <InputGuesser source={"name"} validate={[required()]}/>
        <SelectInput source="type" choices={attributeTypes}/>
        <InputGuesser source={"position"} validate={[required()]}/>
        <ImageInput source="icon" label="Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>
        <InputGuesser source={"comparatorConfig"} validate={[required()]}/>
    </CreateGuesser>
)};

const editRedirect = (basePath, id, data) => {
    console.log('basePath, id, data', basePath, id, data, `/comparator_configs/${encodeURIComponent(data.comparatorConfig)}/attributes`);
    return `/comparator_configs/${encodeURIComponent(data.comparatorConfig)}/attributes`
};

export const AttributeEdit = props => (
    <EditGuesser {...props} redirect={editRedirect}>
        <InputGuesser source={"name"} validate={[required()]}/>
        <SelectInput source="type" choices={attributeTypes}/>
        <InputGuesser source={"position"} validate={[required()]}/>
        <ImageInput source="icon" label="Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>
        <InputGuesser source={"comparatorConfig"} validate={[required()]}/>
    </EditGuesser>
);
