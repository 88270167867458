import {
  CreateGuesser,
  FieldGuesser,
  InputGuesser,
} from "@api-platform/admin";
import React from "react";
import {
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ImageInput,
  FileInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  NumberInput,
  TextInput,
  TabbedShowLayout,
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  BooleanField,
  ImageField,
  FileField,
  ReferenceField,
  ArrayField,
  Datagrid,
  Tab,
  ReferenceManyField,
  TabbedForm,
  FormTab,
  Edit,
} from "react-admin";

import CustomImageField from "../components/fields/CustomImageField";
import CustomVideoField from "../components/fields/CustomVideoField";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import OpenAiModelSelectInput from "../components/OpenAiModelSelectInput";
import DIDVoiceSelectInput from "../components/DIDVoiceSelectInput";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const ShowyAiConfigList = (props) => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"model"} />
    <FieldGuesser source={"temperature"} />
    <FieldGuesser source={"language"} />
  </RefreshableListBySelectedProject>
);
// const models = [
//     {id: 'gpt-4o-mini', name: 'gpt 4o mini'},
//     {id: 'llama3-8b', name: 'Llama3 8b'},
//     {id: 'llama3-70b', name: 'Llama3 70b'},
//     {id: 'llama3.1-405b', name: 'Llama3.1 405b'},
// ];
const languages = [
  { id: "en", name: "en" },
  { id: "ar", name: "ar" },
  { id: "fr", name: "fr" },
];
const voiceProviders = [
  { id: "microsoft", name: "Microsoft" },
  { id: "amazon", name: "Amazon" },
  { id: "google", name: "google" },
  { id: "afflorithmics", name: "afflorithmics" },
  { id: "elevenlabs", name: "elevenlabs" },
  { id: "playHT", name: "playHT" },
];

const toolChoices = [
  { id: "auto", name: "Auto" },
  { id: "required", name: "Required" },
];

const speechModels = [{ id: "whisper-1", name: "whisper-1" }];

export const ShowyAiConfigCreate = (props) => {
  return (
    <CreateGuesser {...props}>
      <InputGuesser source={"name"} validate={[required()]} />
      <OpenAiModelSelectInput
        source="model"
        label="Model"
        defaultValue="gpt-4o"
      />
      <NumberInput
        source={"temperature"}
        validate={[required()]}
        defaultValue={0}
        min={0}
        max={1}
        step={0.1}
      />
      <SelectInput
        source="language"
        choices={languages}
        validate={[required()]}
        defaultValue="en"
      />
      <InputGuesser
        source={"mainPrompt"}
        validate={[required()]}
        multiline
        fullWidth
      />
      <InputGuesser source={"mainPromptTools"} multiline fullWidth />
      <SelectInput
        source="toolChoice"
        choices={toolChoices}
        validate={[required()]}
        defaultValue="required"
      />
      <InputGuesser
        source={"speechToTextPrompt"}
        validate={[required()]}
        multiline
        fullWidth
      />
      <SelectInput
        source="sttModel"
        choices={speechModels}
        defaultValue="whisper-1"
      />
      <InputGuesser
        source={"allowUserToSpeakWhenAvatarIsSpeaking"}
        validate={[required()]}
        defaultValue={false}
      />
      <InputGuesser
        source={"requireSpeechTriggerOnConversationStartOnly"}
        validate={[required()]}
        defaultValue={false}
      />
      <InputGuesser source={"speechPositiveThreshold"} defaultValue={0.9} />
      <InputGuesser source={"speechMinFrames"} defaultValue={3} />
      <InputGuesser source={"speechPrePadFrames"} defaultValue={1} />
      <InputGuesser source={"speechRedemptionFrames"} defaultValue={8} />
      <InputGuesser source={"speechframeSamples"} defaultValue={1536} />
      <InputGuesser
        source={"tensorFlowSpeechProbabilityThreshold"}
        min={0.9}
        max={0.99}
        step={0.01}
        defaultValue={0.9}
      />
      <FileInput
        source="speechKeywordTriggerModel"
        label="Speech Keyword Trigger Model"
        placeholder={<p>Drop your tensorflowjs model.json file here</p>}
      >
        <CustomDocumentField source="name" />
      </FileInput>
      <FileInput
        source="speechKeywordTriggerMetadata"
        label="Speech Keyword Trigger Metadata"
        placeholder={<p>Drop your tensorflowjs metadata.json file here</p>}
      >
        <CustomDocumentField source="name" />
      </FileInput>
      <ArrayInput source="speechKeywordTriggerWeights">
        <SimpleFormIterator inline>
          <FileInput
            source=""
            label="Speech Keyword Trigger Weight"
            placeholder={<p>Drop your tensorflowjs wieghts.bin file here</p>}
          >
            <CustomDocumentField source="name" />
          </FileInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="speechKeywordTriggerWords">
        <SimpleFormIterator inline>
          <TextInput label="Word" />
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser
        source="welcomeMessageTitle"
        toolbar={[
          ["bold", "italic", "underline", "clean"],
          [{ direction: "ltr" }],
        ]}
      />
      <InputGuesser
        source="welcomeMessageParagraph"
        toolbar={[
          ["bold", "italic", "underline", "clean"],
          [{ direction: "ltr" }],
        ]}
      />
      <ImageInput
        source="avatar"
        label="Avatar Image"
        accept="image/*"
        placeholder={<p>Drop your file here</p>}
      >
        <CustomImageField source="contentUrl" />
      </ImageInput>
      <ImageInput
        source="mainBackground"
        label="Main Background Image"
        accept="image/*"
        placeholder={<p>Drop your file here</p>}
      >
        <CustomImageField source="contentUrl" />
      </ImageInput>
      <SelectInput
        source="avatarVoiceProvider"
        choices={voiceProviders}
        validate={[required()]}
        defaultValue="microsoft"
      />
      <DIDVoiceSelectInput
        source="avatarVoiceId"
        label="Avatar Voice Id"
        defaultValue="fr-FR-DeniseNeural"
      />
      <InputGuesser source={"sessionTimeout"} />
      <InputGuesser source="waitingScreenMessage" />
      <FileInput
        source="waitingScreenVideo"
        label="Waiting Screen Video"
        accept="video/*"
        placeholder={<p>Drop your file here</p>}
      >
        <CustomVideoField source="contentUrl" />
      </FileInput>
      <ReferenceArrayInput
        source="devices"
        reference="devices"
        label="Devices"
        filterToQuery={(searchText) => ({ name: searchText })}
        filter={{ "application.slug": "showy-ai" }}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanField source="VoiceClient" />
      {/* <ReferenceArrayInput
        source="service_configs"
        reference="service_configs"
        label="Service Configs"
        filterToQuery={(searchText) => ({ serviceName: searchText })}
      >
        <AutocompleteArrayInput optionText="serviceName" />
      </ReferenceArrayInput>{" "} */}
    </CreateGuesser>
  );
};

export const ShowyAiConfigEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="General">
          <TextInput source="name" validate={[required()]} />
          <OpenAiModelSelectInput
            source="model"
            label="Model"
            defaultValue="gpt-4o"
          />
          <NumberInput
            source="temperature"
            validate={[required()]}
            defaultValue={0}
            min={0}
            max={1}
            step={0.1}
          />
          <SelectInput
            source="language"
            choices={languages}
            validate={[required()]}
            defaultValue="en"
          />
          <TextInput
            source="mainPrompt"
            validate={[required()]}
            multiline
            fullWidth
          />
          <TextInput source="mainPromptTools" multiline fullWidth />
          <SelectInput
            source="toolChoice"
            choices={toolChoices}
            validate={[required()]}
            defaultValue="required"
          />
        </FormTab>

        <FormTab label="Speech">
          <TextInput
            source="speechToTextPrompt"
            validate={[required()]}
            multiline
            fullWidth
          />
          <SelectInput
            source="sttModel"
            choices={speechModels}
            defaultValue="whisper-1"
          />
          <NumberInput source="speechPositiveThreshold" defaultValue={0.9} />
          <NumberInput source="speechMinFrames" defaultValue={3} />
          <NumberInput source="speechPrePadFrames" defaultValue={1} />
          <NumberInput source="speechRedemptionFrames" defaultValue={8} />
          <NumberInput source="speechframeSamples" defaultValue={1536} />
          <NumberInput
            source="tensorFlowSpeechProbabilityThreshold"
            min={0.9}
            max={0.99}
            step={0.01}
            defaultValue={0.9}
          />
          <FileInput
            source="speechKeywordTriggerModel"
            label="Speech Keyword Trigger Model"
            placeholder={<p>Drop your tensorflowjs model.json file here</p>}
          >
            <CustomDocumentField source="name" />
          </FileInput>
          <FileInput
            source="speechKeywordTriggerMetadata"
            label="Speech Keyword Trigger Metadata"
            placeholder={<p>Drop your tensorflowjs metadata.json file here</p>}
          >
            <CustomDocumentField source="name" />
          </FileInput>
          <ArrayInput source="speechKeywordTriggerWeights">
            <SimpleFormIterator inline>
              <FileInput
                source=""
                label="Speech Keyword Trigger Weight"
                placeholder={
                  <p>Drop your tensorflowjs weights.bin file here</p>
                }
              >
                <CustomDocumentField source="name" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="speechKeywordTriggerWords">
            <SimpleFormIterator inline>
              <TextInput label="Word" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Avatar">
          <TextInput source="welcomeMessageTitle" />
          <TextInput source="welcomeMessageParagraph" />
          <CustomVideoField source="avatarIDLEVideo" />
          <SelectInput
            source="avatarVoiceProvider"
            choices={voiceProviders}
            validate={[required()]}
            defaultValue="microsoft"
          />
          <DIDVoiceSelectInput
            source="avatarVoiceId"
            label="Avatar Voice Id"
            defaultValue="fr-FR-DeniseNeural"
          />
        </FormTab>

        <FormTab label="Timeout & Devices">
          <NumberInput source="sessionTimeout" />
          <TextInput source="waitingScreenMessage" />
          <FileInput
            source="waitingScreenVideo"
            label="Waiting Screen Video"
            accept="video/*"
            placeholder={<p>Drop your file here</p>}
          >
            <CustomVideoField source="contentUrl" />
          </FileInput>
          <ReferenceArrayInput
            source="devices"
            reference="devices"
            label="Devices"
            filterToQuery={(searchText) => ({ name: searchText })}
            filter={{ "application.slug": "showy-ai" }}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>

        {/* Service Configs */}
        <FormTab label="llm">
          <ReferenceManyField
            reference="service_configs"
            target="showyAiConfig"
            filter={{ serviceName: "llm", showyAiConfig: props.id }}
          >
            <ServiceConfigDetailsEdit
              serviceName="llm"
              showyAiConfig={props.id}
            />
          </ReferenceManyField>
        </FormTab>
        <FormTab label="stt">
          <ReferenceManyField
            reference="service_configs"
            target="showyAiConfig"
            filter={{ serviceName: "stt", showyAiConfig: props.id }}
          >
            <ServiceConfigDetailsEdit
              serviceName="stt"
              showyAiConfig={props.id}
            />
          </ReferenceManyField>
        </FormTab>
        <FormTab label="tts">
          <ReferenceManyField
            reference="service_configs"
            target="showyAiConfig"
            filter={{ serviceName: "tts", showyAiConfig: props.id }}
          >
            <ServiceConfigDetailsEdit
              serviceName="tts"
              showyAiConfig={props.id}
            />
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const ServiceConfigDetails = ({ data, ids, serviceName, showyAiConfig }) => {
  if (!ids || ids.length === 0 || !data) return null;

  return (
    <>
      {ids
        .filter(
          (id) =>
            data[id].serviceName === serviceName &&
            data[id].showyAiConfig === showyAiConfig
        )
        .map((id) => {
          const record = data[id];
          if (!record) return null;

          return (
            <SimpleShowLayout key={id} record={record}>
              <TextField source="serviceName" />
              <TextField source="provider" />
              <TextField source="model" />
              <TextField label="Options" source="options" />
              <TextField source="VoiceClientId" />
            </SimpleShowLayout>
          );
        })}
    </>
  );
};

export const ShowyAiConfigShow = (props) => {
  console.log("show props", props);
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Config">
          <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="language" />
            <NumberField source="sessionTimeout" />
            <TextField source="waitingScreenMessage" />
            <FileField
              source="waitingScreenVideo"
              title="Waiting Screen Video"
            />
            <ReferenceField source="project" reference="projects">
              <TextField source="name" />
            </ReferenceField>
            <ArrayField source="devices">
              <Datagrid>
                <TextField source="name" />
              </Datagrid>
            </ArrayField>
            <TextField source="mainPrompt" />
            <TextField source="mainPromptTools" />
            <TextField source="toolChoice" />
            <TextField source="speechToTextPrompt" />
            <NumberField source="speechPositiveThreshold" />
            <NumberField source="speechMinFrames" />
            <NumberField source="speechPrePadFrames" />
            <NumberField source="speechRedemptionFrames" />
            <NumberField source="speechframeSamples" />
            <NumberField source="tensorFlowSpeechProbabilityThreshold" />
            <FileField
              source="speechKeywordTriggerModel"
              title="Speech Keyword Trigger Model"
            />
            <FileField
              source="speechKeywordTriggerMetadata"
              title="Speech Keyword Trigger Metadata"
            />
            <ArrayField source="speechKeywordTriggerWeights">
              <Datagrid>
                <FileField source="name" title="Weight File" />
              </Datagrid>
            </ArrayField>
            <ArrayField source="speechKeywordTriggerWords">
              <Datagrid>
                <TextField source="word" />
              </Datagrid>
            </ArrayField>
            <BooleanField source="allowUserToSpeakWhenAvatarIsSpeaking" />
            <BooleanField source="requireSpeechTriggerOnConversationStartOnly" />
            <TextField source="welcomeMessageTitle" />
            <TextField source="welcomeMessageParagraph" />
            <ImageField source="avatar" title="Avatar Image" />
            <FileField source="avatarIDLEVideo" title="Avatar IDLE Video" />
            <ImageField source="mainBackground" title="Main Background Image" />
          </SimpleShowLayout>
        </Tab>
        <Tab label="llm">
          <ReferenceManyField
            reference="service_configs"
            target="showyAiConfig"
            filter={{ serviceName: "llm", showyAiConfig: props.id }}
          >
            <ServiceConfigDetails serviceName="llm" showyAiConfig={props.id} />
          </ReferenceManyField>
        </Tab>
        <Tab label="stt">
          <ReferenceManyField
            reference="service_configs"
            target="showyAiConfig"
            filter={{ serviceName: "stt", showyAiConfig: props.id }}
          >
            <ServiceConfigDetails serviceName="stt" showyAiConfig={props.id} />
          </ReferenceManyField>
        </Tab>
        <Tab label="tts">
          <ReferenceManyField
            reference="service_configs"
            target="showyAiConfig"
            filter={{ serviceName: "tts", showyAiConfig: props.id }}
          >
            <ServiceConfigDetails serviceName="tts" showyAiConfig={props.id} />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const ServiceConfigDetailsEdit = ({
  data,
  ids,
  serviceName,
  showyAiConfig,
}) => {
  if (!ids || ids.length === 0 || !data) return null;

  return (
    <>
      {ids
        .filter(
          (id) =>
            data[id].serviceName === serviceName &&
            data[id].showyAiConfig === showyAiConfig
        )
        .map((id) => {
          const record = data[id];
          if (!record) return null;

          return (
            <div
              className="flex "
              key={id}
              record={record}
              //   save={() => {
              //     /* Custom save logic if needed */
              //   }}
            >
              {/* Editable Fields */}
              <TextInput source="serviceName" label="Service Name" />
              <TextInput source="provider" label="Provider" />
              <TextInput source="model" label="Model" />
              <TextInput source="options" label="Options" />
              <TextInput source="VoiceClientId" label="Voice Client ID" />
            </div>
          );
        })}
    </>
  );
};
