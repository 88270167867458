import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  required,
} from "react-admin";
import {parse} from "query-string";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const HeroProductList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"code"}/>
    <FieldGuesser source={"product"}/>
    <FieldGuesser source={"position"}/>
  </RefreshableListBySelectedProject>
);

export const DeskHeroProductCreate = props => {
  const {deskConfiguration: deskConfigurationIri} = parse(props.location.search);
  const {project: projectIri} = parse(props.location.search);
  let redirect = '/products';

  if (deskConfigurationIri) {
    const encodedDeskConfigurationIri = encodeURIComponent(deskConfigurationIri);
    redirect = `/desk_configurations/${encodedDeskConfigurationIri}/hero_products`
  }

  return (
    <CreateGuesser {...props} redirect={redirect}
                   initialValues={{
                     deskConfiguration: deskConfigurationIri,
                     project: projectIri
                   }}>
      <InputGuesser source="code"/>
      <InputGuesser source="position"/>

      <ReferenceInput
        allowEmpty={true}
        source="product"
        reference="products"
        label="Product"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="deskConfiguration"
        reference="desk_configurations"
        label="Desk Configuration"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const deskConfigurationIri = encodeURIComponent(data.deskConfiguration);
  return deskConfigurationIri ? `/desk_configurations/${deskConfigurationIri}/hero_products` : '/hero_products';
};

export const DeskHeroProductEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <InputGuesser source="code"/>
    <InputGuesser source="position"/>

    <ReferenceInput
      allowEmpty={true}
      source="product"
      reference="products"
      label="Product"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="deskConfiguration"
      reference="desk_configurations"
      label="Desk Configuration"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
