import React from "react";
import {FieldGuesser, CreateGuesser, InputGuesser, EditGuesser} from "@api-platform/admin";
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  FileInput,
  TextInput,
  SelectInput,
  required
} from "react-admin";
import CustomDocumentField from "./fields/CustomDocumentField";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const MailConfigList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"subject"}/>
    <FieldGuesser source={"fromName"}/>
    <FieldGuesser source={"fromMail"}/>
    <FieldGuesser source={"toEmail"}/>
    <FieldGuesser source={"objectType"}/>
  </RefreshableListBySelectedProject>
);

export const MailConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>
    <InputGuesser source="subject"/>
    <InputGuesser source="fromName"/>
    <InputGuesser source="fromMail"/>
    <InputGuesser source="toEmail"/>

    <SelectInput source="objectType" initialValue={'text'} choices={[
      {id: 'vehicle', name: 'Vehicle'},
      {id: 'post', name: 'Post'},
      {id: 'person', name: 'Person'},
    ]} validate={[required()]}/>

    <TextInput source="postAttachmentTypes"
               parse={v => JSON.parse(v)}
               format={v => JSON.stringify(v)}
               fullWidth={true}
               multiline={true}/>

    <FileInput source="template" label="Template" placeholder={<p>Drop your file here</p>}>
      <CustomDocumentField source="name"/>
    </FileInput>

    <ReferenceArrayInput
      source="applications"
      reference="applications"
      label="Applications"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={true}
      source="brand"
      reference="brands"
      label="Brand"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="product"
      reference="products"
      label="Product"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const MailConfigEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name"/>
    <InputGuesser source="subject"/>
    <InputGuesser source="fromName"/>
    <InputGuesser source="fromMail"/>
    <InputGuesser source="toEmail"/>
    <SelectInput source="objectType" initialValue={'text'} choices={[
      {id: 'vehicle', name: 'Vehicle'},
      {id: 'post', name: 'Post'},
      {id: 'person', name: 'Person'},
    ]} validate={[required()]}/>
    <TextInput source="postAttachmentTypes"
               parse={v => JSON.parse(v)}
               format={v => JSON.stringify(v)}
               fullWidth={true}
               multiline={true}/>

    <FileInput source="template" label="Template" placeholder={<p>Drop your file here</p>}>
      <CustomDocumentField source="name"/>
    </FileInput>

    <ReferenceArrayInput
      source="applications"
      reference="applications"
      label="Applications"
      filterToQuery={searchText => ({name: searchText})}
      format={v => v && v.map(x => x['@id'])}>
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={true}
      source="brand"
      reference="brands"
      label="Brand"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="product"
      reference="products"
      label="Product"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
