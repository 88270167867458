import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  required,
  Datagrid,
  ShowButton,
  EditButton,
  ReferenceManyField,
  Tab,
  TabbedShowLayout,
} from "react-admin";
import CustomMediaField from "./customMediaField";
import AddPlayListItemButton from "./addPlayListItemButton";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const PlayListList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"project"}/>
  </RefreshableListBySelectedProject>
);

export const PlayListCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
  </CreateGuesser>
);
export const PlayListEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} validate={[required()]}/>


      </Tab>
      <Tab label="Items" path="items">
        <ReferenceManyField
          addLabel={false}
          reference="play_list_items"
          target="playList">
          <Datagrid>
            <CustomMediaField />
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddPlayListItemButton />
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
