import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  required,
  ImageInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import {parse} from "query-string";

export const ProductVariantCreate = props => {
  const {product: productUri, product_version: productVersionUri} = parse(props.location.search);
  const encodedProductUri = encodeURIComponent(productUri);
  const redirect = `/products/${encodedProductUri}/variants`;

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{ product: productUri}}>
      <InputGuesser source={"name"} validate={[required()]}/>
      <InputGuesser source={"price"} validate={[required()]}/>
      <ReferenceInput
        allowEmpty={true}
        source="product"
        reference="products"
        label="Product"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const productUri = encodeURIComponent(data.product);
  return `/products/${productUri}/variants`;
};

export const ProductVariantEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"price"} validate={[required()]}/>
    <ReferenceInput
      allowEmpty={true}
      source="product"
      reference="products"
      label="Product"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
