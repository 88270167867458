import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {FileInput, UrlField, TextField, Show, Labeled, SimpleShowLayout} from "react-admin";
import CustomVideoField from "../components/fields/CustomVideoField";
import VideoField from "../components/fields/VideoField";
import FileSizeField from "../components/fields/FileSizeField";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const VideoList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"duration"}/>
    <UrlField source={"contentUrl"}/>
    <FieldGuesser source={"fileFormat"}/>
  </RefreshableListBySelectedProject>
);

export const VideoCreate = props => (
  <CreateGuesser {...props}>
    <FileInput source="file" label="File" placeholder={<p>Drop your file here</p>}>
      <CustomVideoField source="name"/>
    </FileInput>
  </CreateGuesser>
);
export const VideoEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"}/>
  </EditGuesser>
);
export const VideoShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source={"name"}/>
      <TextField source={"width"}/>
      <TextField source={"height"}/>
      <Labeled label="Content size">
        <FileSizeField source={"contentSize"}/>
      </Labeled>
      <TextField source={"contentPath"}/>
      <TextField source={"encodingFormat"}/>
      <TextField source={"fileFormat"}/>
      <TextField source={"bitRate"}/>
      <TextField source={"frameRate"}/>
      <TextField source={"frameCount"}/>
      <TextField source={"duration"}/>
      <TextField source={"spaceKey"}/>
      <TextField source={"status"}/>
      <UrlField source={"contentUrl"}/>
      <Labeled label="Video preview">
        <VideoField source={"contentUrl"} label={'Video preview'}/>
      </Labeled>
    </SimpleShowLayout>
  </Show>
);
