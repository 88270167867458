import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const CategoryList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
  </RefreshableListBySelectedProject>
);

export const CategoryCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>
  </CreateGuesser>
);

export const CategoryEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name"/>
  </EditGuesser>
);
