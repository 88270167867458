import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";

export const DeviceTypeCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>
  </CreateGuesser>
);

export const DeviceTypeEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name"/>
  </EditGuesser>
);
